@import "../../vars";

.layout {
  min-height: 100%;
}

.container {
  background: var(--color-white);
  max-width: 600px;
  margin: $padding-md auto $padding-pl;
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-xl;
  padding: 0;
}

.banner {
  color: var(--color-white);
  width: 100%;
  height: auto;
  min-height: 150px;
  // min-height: 262.5px;
}

.content {
  padding: 0 $padding-pm $padding-md;
}

h2.title {
  text-align: center;
  font-weight: 500;
  color: var(--color-gray-8);
  padding-top: $padding-md;
}

.table {
  :global(.ant-table-row) {
    transition: background-color 0.3s;
  }

  :global(.custom-table .ant-table-row:hover) {
    background-color: var(--color-gray-3);
  }

  :global(.custom-table .ant-table-cell) {
    padding: 8px 16px;
  }

  :global(.ant-table-row:last-child .ant-table-cell) {
    border-bottom: 0;
  }
}

tr > td.avatar {
  padding: 0;
}

.number {
  color: var(--color-gray-7);
  text-align: center;
}

.value {
  text-align: center;
  color: var(--color-gray-7);

  padding: $padding-xs;

  > div {
    padding: $padding-xs / 2 $padding-xs;
    //border-radius: $border-radius-xl;
    //background: var(--color-white);
  }
}

.current {
  color: var(--color-gray-9);
  //font-weight: 500;
}

.pagination {
  :global(.ant-pagination-item-active), :global(.ant-pagination-item-active:hover), :global(.ant-pagination-item:hover) {
    border-color: var(--color-gray-8);

    a {
      color: var(--color-gray-8);
    }
  }
}

@media only screen and (min-width: $screen-xs) {
  .layout {
    background: var(--color-primary-bg);
  }

  .banner {
    border-radius: $border-radius-xl $border-radius-xl 0 0;
  }

  .table {
    min-height: 350px;

    :global(.ant-table) {
      font-size: $font-size-xl;
      color: var(--color-gray-8);
    }
  }

  //.name {
  //  font-size: $font-size-xl + 3;
  //}

  .current {
    font-size: $font-size-xl + 3;
  }

  .number {
    width: 70px;
  }

  tr > td.avatar {
    width: $icon-size-xl;
    min-width: $icon-size-xl;
    height: $icon-size-xl;

    :global(.ant-avatar) {
      width: $icon-size-xl;
      height: $icon-size-xl;
      line-height: $icon-size-xl;
    }
  }
}


@media only screen and (max-width: $screen-xs-max) {
  .layout {
    :global(.page-container) {
      padding: 0;
    }
  }

  .container {
    margin: 0 0;
    box-shadow: none;
    border-radius: 0;
  }
  .content {
    padding: 0 $padding-ps $padding-pm;
  }

  h2.title {
    font-size: $font-size-h2;
  }

  .current {
    text-decoration: underline;
  }
}