@import "../../../../../vars";

$button_icon_color: #7a7a7a;

.node_button_group {
  border-radius: $border-radius-md;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  .node_button {
    border: 2px solid transparent;
    color: var(--color-gray-9);
    height: 30px;

    &:global(.ant-btn-icon-only) {
      width: 30px;
    }

    &:after {
      display: none;
    }

    &:hover, &:focus, &.active {
      color: var(--color-gray-9);
      // background: var(--color-gray-4);
      border: 2px solid var(--color-primary);
    }

    .color_circle {
      margin-top: 4px;
      border-radius: 50%;
      width: 18px;
      height: 18px;
    }
  }

  .node_button + .node_button {
    margin-left: 1px;
  }
}


