@import "../../vars";

.row {
  // max-width: $screen-lg;
  margin-left: auto;
  margin-right: auto;

  .col {
    text-align: center;
    padding: 0 $padding-xs $padding-md;
  }
}


.description {
  display: none; // TODO: remove it later
  color: var(--color-gray-8);
}

.link {
  img {
    max-width: 250px;
    width: 100%;
    transition: all 0.3s linear;

    &:hover {
      transform: scale(1.1);
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .row {
    padding: 0 $padding-xp;

    .col {
      padding-bottom: $padding-ps;
    }
  }

  h3.title {
    font-size: 18px;
  }
}