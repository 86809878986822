@import "../../vars";

.menu-accordion {
  position: relative;
  width: 100%;
  margin: 20px 0 0;
  font-size: 16px;
  color: var(--color-gray-7);
  // transition: all 300ms;

  &:hover, &.active {
    color: var(--color-gray-9);
  }

  .arrow {
    font-size: 14px;
    transition: transform 200ms linear;
  }
  &.opened {
    .arrow {
      transition: transform 200ms linear;
      transform: rotate(180deg);
    }
  }

  .accordion_header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    cursor: pointer;
    color: inherit;
    font-weight: inherit;
    margin: 0 26px;

    .accordion_header_icon {
      font-size: $icon-size-lg;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: inherit;
      width: 24px;
      height: 24px;
    }

    .accordion_header_title {
      flex-grow: 1;
      margin-left: 15px;
      color: inherit;
      font-weight: inherit;
    }

    .accordion_header_corner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .accordion_content {
    position: relative;
    overflow: hidden;
    //transition: all 300ms;
    // height: 0;

    &.active {
      // height: auto;
    }

    .accordion_list_container {
      position: relative;
      list-style-type: none;
      padding: 10px 0 5px 0;
      margin: 0 !important;

      .divider {
        margin-bottom: $padding-ps;
        height: 1px;
      }

      .accordion_list_item a {
        position: relative;
        cursor: pointer;
        padding: 8px 0 5px 66px;
        font-size: 14px;
        line-height: 18px;
        color: var(--color-gray-7);
        font-weight: 400;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        box-orient: vertical;
        overflow: hidden;

        &:hover {
          color: var(--color-gray-9);
        }

        &.active {
          background-color: var(--color-gray-3);
          color: var(--color-gray-9);
        }
      }
    }
  }
}