@import '../../../mixins';
@import '../../../vars';

//:global(.form-logic-fields) {
//  :global(.ant-input:focus) {
//    background-color: var(--color-gray-4);
//    // box-shadow: 0 0 0 2px var(--color-primary-light);
//  }
//}

.logicFieldWrapper {
  margin-bottom: $padding-sm;

  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.logicFieldName {
  :global(.ant-input) {
    font-size: $font-size-sm;
    text-align: right;
    margin-bottom: $padding-xs / 2;
    // border-bottom: $border-width-md solid var(--color-gray-5);
    // border-radius: 0;
  }

  :global(.ant-form-item-explain-error) {
    text-align: right;
  }
}

.logicFieldInput {
  :global(.ant-input) {
    color: var(--color-gray-7);
  }
}

.logicHeadingFieldLabel {
  :global(.ant-input) {
    color: var(--color-gray-8);
    font-size: $font-size-xl;
    font-weight: 600;
    line-height: 1.35;
    padding: 0;
  }
}

.logicFieldDesc, .logicFieldLabel {
  margin-left: -$padding-xs;
}

.logicFieldType {
  padding: $padding-xs;

  :global(.fix-checkbox-align > span:first-child) {
    margin-top: -4px;
  }

  :global(.ant-checkbox + span) {
    padding: 0;
  }
}

.logicFieldDefault :global(.ant-input){
  text-align: right;
}

.logicFieldDesc :global(.ant-input), .logicFieldType {
  color: var(--color-gray-8);
  font-size: $font-size-xs;
  padding-top: 8px;
}

.logicFieldLabel :global(.ant-input) {
  color: var(--color-gray-8);
  font-size: $font-size-sm;
}

.logicFieldButton:global(.ant-btn-icon-only) {
  padding: 0;
  height: 29px;
  width: 30px;
  margin: 3px 0 1px $padding-xp;
}

