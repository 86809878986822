@import '../../../../../../mixins';

.container-catalog-wrapper {
    .ant-tabs > .ant-tabs-nav {
        margin-bottom: 30px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
        @include flex_row(flex-start);
        width: 100%;
    }
}
