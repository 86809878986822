.arrow::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 35px solid white;
    border-radius: 8px;
    margin-top: 0;
}
