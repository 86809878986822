@import "../../../../../../vars";

.button_left_menu {
    color: var(--color-gray-7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    box-shadow: 0 0 transparent;
    margin: 20px 0;
    line-height: 32px;
    padding: 0 26px;
    transition: all 0ms;

    &:hover {
        color: var(--color-gray-9);
    }

    &.active, &.active:hover {
        // font-weight: 500;
        color: var(--color-gray-9);
    }

    .menu_button_title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: inherit;
        font-weight: inherit;

        .title_menu_button_icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 15px;
            color: inherit;
            font-weight: inherit;

            & svg {
                width: 24px;
                height: 24px;
            }
        }

        .title_menu_button_text {
            font-size: 16px;
            color: inherit;
            font-weight: inherit;
        }
    }
}


