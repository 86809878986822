@import "../../../vars";
@import '../../../mixins';

$fsize: $font-size-lg;
$date_minwidth: 120px;
$role_minwidth: 120px;
$act_minwidth: 85px;

.wrapper_table_head {
  background: transparent;
  margin-top: 50px;
  font-weight: 400;
}

.table_head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fsize+px;

  .head_title {
    display: flex;
    align-items: center;
    height: ($fsize+8)+px;
    width: 60%;
    //flex-grow: 1;

    .project_search {
      border: 0;
      margin-right: 20px;
      font-weight: 400;
      font-size: 18px;
      color: var(--color-gray-9);
    }
  }

  .head_date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    min-width: $date_minwidth;
    margin-left: -15px;
    margin-right: 15px;
  }

  .head_role {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    min-width: $role_minwidth;
  }

  .head_common {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    min-width: $role_minwidth;
  }

  .wrapper_filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: $role_minwidth;
  }

  .head_actions {
    min-width: $act_minwidth;
  }
}

.wrapper_row {
  @include flex_row(flex-start);
  //margin: 20px 0;
  height: 80px;
  font-size: $fsize+px;

  &:hover {
    background-color: #fafafa;
  }

  .row_title {
    width: 60%;
    height: 60px;
    @include flex_row(flex-start);
    cursor: pointer;

    .title {
      margin-left: 15px;
      height: ($fsize+8)+px;
      @include line_clamp(1);
      padding-right: 5px;
    }
  }

  .date {
    @include flex_row(flex-start);
    color: var(--color-gray-7);
    min-width: $date_minwidth;
    font-weight: normal;

    & div {
      height: ($fsize+8)+px;
      @include line_clamp(1);
    }
  }

  .role {
    @include flex_row(flex-start);
    font-weight: normal;
    min-width: $role_minwidth;

    & div {
      height: ($fsize+8)+px;
      @include line_clamp(1);
    }
  }

  .common {
    @include flex_row(flex-start);
    font-weight: normal;
    min-width: $role_minwidth;

    & div {
      height: ($fsize+8)+px;
      @include line_clamp(1);
    }
  }

  .actions {
    @include flex_row(flex-start);
    font-size: 24px;
    font-weight: 500;
    min-width: $act_minwidth;
  }
}

.action_content {
  margin-top: -18px;
  cursor: pointer;
  width: 40px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color:black;
}

.wrapper_table {
  //position: relative;
  //background: #FFFFFF;

  // box-shadow: 0 8px 35px $color-shadow-2;
  // box-shadow: 5px 5px 25px $color-shadow-1;
  box-shadow: $card-shadow;
  border-radius: $border-radius-lp;
  //padding: 20px;
  //color: black;
  //font-weight: 400;
  overflow: hidden;

  img {

    & img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ant-dropdown-trigger{
    display: none !important;
  }

  table > thead > tr > th > div > div > span{
      display: none ;
  }

  table > thead > tr > th:before{
    background-color: rgba(0,0,0,.00) !important;

  }

  table > tbody > tr > td {
    border-bottom: 1px solid transparent !important;

  }

}


.wrapper_last_head_cols {
  @include flex_row(space-between);
  width: 40%;
}

.wrapper_last_cols {
  @include flex_row(space-between);
  width: 40%;
}

.button_filter {
  @include flex_row(center);
  width: 44%;
  height: 25px;
  position: absolute;
  right: 0;
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #49a7ff !important;
    border-color: #49a7ff !important;
    color: #fff !important;
  }
}

.button_reset {
  @include flex_row(center);
  width: 44%;
  height: 25px;
  position: absolute;
  left: 0;
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  border-radius: 4px;
  color: #111;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f1f1f1 !important;
    color: #111 !important;
  }
}

.wrapper_button_filter {
  position: relative;
  width: 100%;
  height: 30px;
  margin-top: 12px;
}
