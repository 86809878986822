@import "../../../../../../vars";

.header_top_buttons {
  display: flex;
  align-items: center;
  position: absolute;
  right: 9px;
  z-index: 10;
  left: unset;
  top: 11px;

  .save_button {
    margin-right: 10px;
  }

  .save_status {
    margin-right: 10px;
    margin-top: -3px;
  }
}

.control_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;
  color: var(--color-gray-9);
  position: absolute;
  top: 15px;
  left: -50px;
  border-radius: 6px;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
  background-color: var(--color-white);

  &.active {
    background-color: var(--color-primary);
    color: var(--color-white);
  }

  &:hover{
    background-color: var(--color-gray-3);

    &.active {
      background-color: var(--color-primary-lighten);
    }
  }

  &:focus {
    outline: none;
  }

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }
}

.close_mobile {
  right: 15px;
  z-index: 10;
  left: unset;
  border-radius: 6px;
  display: none
}