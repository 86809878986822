@import "../../../vars";

$card-body-padding: $padding-ps + 2;

.list_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

/*.tabs {
  &:global(.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab) {
    padding-left: $padding-xp;
    padding-right: $padding-pm;
  }

  :global(.ant-tabs-tab .icon) {
    margin-right: $padding-xp;
    width: 20px;
    display: inline-block;
    text-align: center;
  }

  :global(.ant-tabs-tabpane) {
    padding-bottom: $padding-sm;
    padding-top: $padding-sm;
  }

  :global(.ant-tabs-nav) {
    padding-bottom: $padding-sm;
    padding-top: $padding-sm;
  }
}*/

.card {
  box-shadow: $box-shadow-base;
  display: inline-block;
  width: 300px;
  max-width: 100%;
  overflow: hidden;
  cursor: default;

  :global(.ant-card-body) {
    padding: $card-body-padding;
    border-top: 1px solid var(--color-gray-4);
  }

  :global(.ant-card-cover) {
    overflow: hidden;
    height: 300px;
    width: 300px;
    max-width: 100%;

    img {
      color: var(--color-white);
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    outline: 2px solid var(--color-primary);
  }

  :global(.ant-card-actions) {
    border-top: none;

    > li {
      padding: 0 $padding-xp $padding-sm $card-body-padding;
      margin: 0;
      text-align: left;
    }

    > li:not(:last-child) {
      border-right: none;
    }

    > li:last-child {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.icon {
  font-size: 60px;
  color: var(--color-gray-5);
  margin-top: 25%;
  text-align: center;
  display: block;
}

@media only screen and (max-width: $screen-xs-max) {
  .card {
    :global(.ant-card-meta-title) {
      font-size: 16px;
    }
  }

  .icon {
    margin-top: 16%;
  }
}