@import "../../../../vars";

.node_background {
  background: var(--color-white);
  border-radius: $border-radius-md;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.graph_node {
  border-radius: $border-radius-md;
  opacity: 0.9 !important;
  cursor: default;
  overflow: hidden;

  width: auto;
  max-width: 250px;
  min-width: 150px;
  padding: 0;

  .node_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--color-white);
    font-size: $font-size;
    line-height: $font-size-md;

    border-radius: $border-radius-md $border-radius-md 0 0;
    padding: $padding-xp / 2 $padding-xp;
    margin: 0;

    position: relative;
    cursor: grab;
    z-index: -1;

    .node_title_text {
      position: relative;
      display: inline-block;
      margin-left: 7px;
      margin-top: 1px;
    }

    .node_id {
      position: relative;
      margin-top: 2px;
      margin-left: 10px;
    }
  }

  .action_title {
    background: linear-gradient(90deg, #81C939 0%, #ADE680 100%);
  }

  .event_title {
    background: linear-gradient(90deg, #7367FF -7.14%, #B0AEFF 100%);
  }

  .message_title {
    background: linear-gradient(268.6deg, #7ac0ff 0.36%, #3697f2 96.95%);
  }

  .condition_title {
    background: linear-gradient(90deg, #F8AA34 0%, #FFDA95 100%);
  }

  .timer_title {
    background: linear-gradient(90deg, #B071FF 0%, #D9A8FF 100%);
  }
}

[data-ignored="true"] {
  .node_background, .graph_node{
    opacity: 0.5 !important;
  }
}

.node_text {
  // display: inline-block;
  position: relative;
  width: 100%;
  // color: var(--color-gray-9);
  font-size: $font-size;
  line-height: 1.5em;
  font-weight: 500;

  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  user-select: none;
  -webkit-user-select: none;
  padding: 10px;
  margin: 0;
}

.node_image_wrapper {
  position: relative;
  width: 100%;
  padding: 0 10px 10px;
}
.node_image {
  border-radius: $border-radius-md;
  width: 100%;
  height: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

:global(.cursor-grabbing) {
  .node_title {
    cursor: grabbing;
  }
}

:global(.react-flow__node) {
  border-radius: $border-radius-md;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
  opacity: 0.95;
}

.node_selected {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 6px;
  background-color: rgba(0, 0, 255, 0.2);
}

:global(.react-flow__node.selected) {
  outline: 2px solid var(--color-primary);
}

:global(.react-flow__node[selected="true"]) {
  .node_selected {
    display: block;
  }
}

@media only screen and (max-width: $screen-xs-max) {
  :global(.react-flow__node.selected) {
    outline: none;
  }
}