@import "../../../../../../vars";

.node_title_control_wrapper {
  flex-grow: 1;
  margin-right: $padding-xp;
}

//.node_graph_title_wrapper {
//  display: inline-grid;
//
//  textarea {
//    width: 100%;
//  }
//
//  &::after {
//    content: attr(data-value) ' ';
//    visibility: hidden;
//    white-space: pre-wrap;
//  }
//}

.node_title_control {
  width: 100%;

  &:not(:focus, &:active) {
    height: 35px !important;
  }
}
