@import "../../vars";

.container_wrapper {
  padding: 0 $padding-md / 2 $padding-md $padding-md;
}

.container {
  // display: flex;
  padding-top: $padding-xs;
  font-size: $font-size-md;
}


@media only screen and (max-width: $screen-xs-max) {
  .container_wrapper {
    padding-left: $padding-sm + 2;
    padding-right: $padding-sm;
  }
  .container {
    iframe {
      max-width: 100%;
      height: calc(100vw * 0.5625)
    }
  }
}

