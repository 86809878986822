.vertical-container{
  padding: 30px 0;
}

.horizontal-container{
  padding: 0 30px;
}

.full-container{
  padding: 30px;
}

.color-primary{
  color: var(--color-primary)
  // var(--color_primary);
}

.grid-style{
  width: 25%;
  text-align: center;
}



