@import "../../../vars";

.cardListWrapper {
  width: 100%;
  margin-bottom: $padding-md;

  //:global(.ant-card-body) {
  //  justify-content: center;
  //  flex-wrap: wrap;
  //}
}

.card {
  min-width: 100px;
  max-width: 187px;
  // text overflow ellipsis
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // end text overflow ellipsis
}

.cardIcon {
  font-size: $icon-size-lg;
  color: var(--color-gray-7);
  margin-bottom: $padding-xs;
}


@media only screen and (max-width: $screen-xs-max) {
  .card {
    max-width: 110px;
    font-size: $font-size-xs;
  }
}

