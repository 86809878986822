@import '../../../../../mixins';
@import '../../../../../vars';

$button_height: 40px;
$button_border_radius: 6px;

.wrap_group_buttonlt {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 15px;
  top: 15px;
  height: $button_height;
  z-index: 100;
  background: transparent;
}

.left_box {
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: $button_border_radius;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
  height: 100%;
  overflow: hidden;
}

.wrap_logo {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  background-color: var(--color-white);
  color: var(--color-gray-9);

  &:hover {
    color: var(--color-gray-8);
  }
}

.plane_pict {
  width: 24px;
  height: 24px;
}

.plane_text {
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.block_icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $button_height;
  height: 100%;
  background-color: var(--color-white);
  margin-left: 2px;
  overflow: hidden;
}

.avatar {
  width: 26px;
  height: 26px;
  border-radius: 26px;
}

// ---------------------------------------------------------------

.middle_box {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-right: 16px;
  height: $button_height;
  border-radius: $button_border_radius;
  overflow: hidden;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);

  .wrapper_drop {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 27px 8px 16px;
    background-color: var(--color-white);
    border-radius: $button_border_radius 0 0 $button_border_radius;
    width: 230px;
    font-weight: 500;
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: 26px;
  }

  .downOutlined {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 12px; // здесь 12, а не 14 - меньшим размером wrapper'а чуток опустили уголок

    & svg {
      width: 22px;
      height: 14px;
    }
  }
}


.item_icon {
  float: right;
  margin-left: 10px;
  color: var(--color-gray-8);
}

.item_disabled {
  color: var(--color-gray-6);

  .item_icon {
    color: var(--color-gray-7);
  }
}

.item_active {
  font-weight: bold;
}

.icon_button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--color-gray-8);
  background-color: var(--color-white);
  border: 0;
  padding: 0;
  width: $button_height;
  height: $button_height;

  &:hover {
    background-color: var(--color-gray-4);
    color: var(--color-gray-8);
  }

  &:focus {
    outline: none;
  }

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }
}

.setting_button_middle_box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $button_height;
  height: $button_height;
  background-color: var(--color-white);
  border: 0;
  cursor: pointer;
  color: var(--color-gray-9);
  margin-left: 2px;
  border-radius: 0 $button_border_radius $button_border_radius 0;
  padding-bottom: 0;

  &:hover {
    background-color: var(--color-gray-4);
    color: var(--color-gray-8);
  }

  &:focus {
    outline: none;
  }

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--color-gray-9);
  }
}

.right_box {
  margin-right: 16px;
  position: relative;
  height: $button_height;
  border-radius: $button_border_radius;
  border: 0;
  overflow: hidden;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
  background-color: transparent;
  padding: 0;

  .left_div {
    position: relative;
    background-color: var(--color-white);
    width: 168px;
    height: $button_height;
    display: inline-block;
  }

  .right_div {
    position: relative;
    background-color: var(--color-white);
    width: $button_height;
    height: $button_height;
    margin-left: 2px;
    display: inline-block;
  }
}

// ---------------------------------------------------------------

.wrapper_title_editor {
  @include block44(var(--color_ff));
  @include flex_row(flex-start);
  width: 94%;
  max-width: 410px;
  position: absolute;
  top: 90px;
  left: 50%;
  margin: 0 5px;
  padding-left: 13px;
  transform: translateX(-50%);
  z-index: 10;

  .wrapper_input {
    flex-grow: 1;

    .input_title {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      border: 0;
      outline: none;

      &:focus {
        border: 0;
        outline: none;
      }
    }
  }

  .wrapper_button {
    @include flex_row(space-around);
    @include block(95, 30);
    min-width: 95px;
    padding: 0 7px;

    .button_confirm {
      @include flex_row(center);
      @include block(30, 30);
      background-color: var(--color_f1);
      border-radius: $button_border_radius;
      cursor: pointer;

      &:hover {
        background-color: var(--color_ea);
      }
    }
  }
}

.hidden_lg {

}

@media only screen and (max-width: $screen-lg-max) {
  .hidden_lg {
    display: none !important;
  }
  .right_box {
    overflow: visible;

    .left_div {
      width: 40px;
      background-color: transparent;
    }
  }
}



@media only screen and (max-width: 1450px) {
  .hidden_lg_plus {
    display: none !important;
  }
}

@media only screen and (max-width: $screen-md-max) {
  .block_icon {
    border-radius: 6px;
    margin-left: 0;
  }

  .middle_box .wrapper_drop {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 16px;
    width: $button_height;
    background-color: var(--color-white);
    border-radius: $button_border_radius 0 0 $button_border_radius;
    overflow: hidden;
    line-height: 26px;

    .graph_title {
      display: none;
    }

    .downOutlined {
      right: 8px;
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .hidden_xs {
    display: none;
  }
}
