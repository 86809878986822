@import "../../../../vars";
@import "../../../../mixins";

.widthBtns {
  width: 100%;
  display: flex;
  align-content: center;
  margin-top: $padding-sm;

  button:not(:global(.ant-btn-link)) {
    color: var(--color-gray-6);
  }

  .flip {
    transform: rotate(90deg);
  }

  .desc {
    margin-left: $padding-sm;
    line-height: $btn-size-md;
    padding-right: $padding-ps;
    flex-grow: 1;
  }
}

.containerWrapper {
  padding: 0 $padding-md / 2 $padding-md $padding-md;
}

.container {
  // display: flex;
  padding-top: $padding-xs $padding-xp + 2;
  font-size: $font-size-md;
  margin-top: $padding-ps;
}

.containerInner {
  box-shadow: $card-shadow;
  margin-bottom: $padding-md;
  border-radius: $border-radius-md;
}

.containerAdd {
  text-align: center;
  margin-top: $padding-ps;
  padding: $padding-xp;

  button:global(.ant-btn-text) {
    @include ellipsis();
    color: var(--color-gray-6);
    border: $border-width-md dashed;
    width: 100%;
    padding: $padding-xs;
    height: auto;

    :global(.anticon) {
      padding-bottom: 1px;
    }

    &:hover, &:focus {
      color: var(--color-gray-7);
      border: $border-width-md dashed var(--color-gray-7);
    }
  }
}

.con {
  z-index: 101;
  overflow: hidden;
  // margin-bottom: $border-width-md;

  > div:first-child {
    min-height: $padding-pm;
  }

  &:first-child {
    border-radius: $border-radius-md $border-radius-md 0 0;
  }

  .placeholder {
    min-height: 0;
    // flex-grow: 0;
  }

  .conControls {
    z-index: 201;
    height: 0;
    float: left;
    position: absolute;
    margin-top: -$border-radius-md;
    margin-left: $padding-xs / 2;
    overflow: hidden;
    transition: all $transition-time $transition-style;
  }

  &:hover, &.current {
    z-index: 151;
    outline: $border-width-md solid var(--color-gray-5);
    outline-offset: -$border-width-md / 2;
    border-radius: $border-radius-md;

    .conControls {
      z-index: 251;
      height: $btn-size-sm;
      transition: all $transition-time $transition-style;
      margin-left: $padding-xs / 2;
    }
  }

  .conControlButton {
    z-index: 301;
    background: var(--color-white);
    width: $btn-size-sm;
    //min-height: $btn-size-sm;

    &:focus {
      color: var(--color-gray-9);
      border-color: var(--color-gray-5);
    }

    &:hover {
      z-index: 351;
      color: var(--color-white);
      background: var(--color-primary);
      border-color: var(--color-primary);
    }
  }
}

.group {
  z-index: 102;
  min-width: $padding-pm;
  min-height: $padding-pm;
  // margin-bottom: $border-width-md;

  > div:first-child {
    min-height: $padding-pm;
  }

  &:first-child .blockWrapper:not(.current, :hover) {
    border-radius: $border-radius-md $border-radius-md 0 0;
  }

  .placeholder {
    min-height: 0;
  }

  .groupControls {
    z-index: 202;
    height: 0;
    position: absolute;
    margin-top: -$btn-size-sm + $border-radius-md;
    margin-left: -$btn-size-sm * 4;
    overflow: hidden;
    transition: all $transition-time $transition-style;
  }

  &:hover, &.current {
    z-index: 152;
    outline: $border-width-md solid var(--color-gray-5);
    outline-offset: -$border-width-md / 2;
    border-radius: $border-radius-md;

    .groupControls {
      z-index: 252;
      height: $btn-size-sm;
      transition: all $transition-time $transition-style;
      margin-top: -$btn-size-sm + $border-radius-md;
    }
  }

  &:hover {
    z-index: 172;
  }

  .groupControlButton {
    z-index: 302;
    background: var(--color-white);
    width: $btn-size-sm;
    //min-height: $btn-size-sm;

    &:focus {
      color: var(--color-gray-9);
      border-color: var(--color-gray-5);
    }

    &:hover {
      z-index: 352;
      color: var(--color-white);
      background: var(--color-primary);
      border-color: var(--color-primary);
    }
  }
}

.blockWrapper {
  z-index: 103;
  cursor: pointer;
  // overflow: hidden;
  min-width: $padding-pm;
  min-height: $padding-pm;

  .controls {
    z-index: 203;
  }

  &:hover, &.current {
    z-index: 153;
    outline: $border-width-md solid var(--color-primary);
    outline-offset: -$border-width-md / 2;
    border-radius: $border-radius-md;

    .controls {
      z-index: 253;
      float: right;
      height: $btn-size-md;
      transition: all $transition-time $transition-style;
      margin-top: -$btn-size-md + $border-radius-md;
    }
  }

  &:hover {
    z-index: 173;
  }

  .controlButton {
    z-index: 303;

    &:focus, &:hover {
      z-index: 353;
    }
  }
}

.controls {
  height: 0;
  margin-top: 0;
  margin-left: -($btn-size-md * 4);
  transition: all $transition-time $transition-style;
  position: absolute;
  overflow: hidden;
}

.controlButton {
  min-width: $btn-size-md;
  border-color: var(--color-primary);
  background-color: var(--color-white);

  &:focus {
    color: var(--color-gray-9);
    border-color: var(--color-primary);
  }

  &:hover {
    color: var(--color-white);
    background: var(--color-primary);
    border-color: var(--color-primary);
  }
}

.smallWidth {
  // border: 2px solid darkred;

  .groupControls {
    margin-left: -$btn-size-sm * 2;
  }

  .blockWrapper .controls {
    margin-top: -$btn-size-sm + $border-radius-md;
    margin-left: -$btn-size-sm * 2;
  }

  .controlButton {
    min-width: $btn-size-sm;
    width: $btn-size-sm;
    height: $btn-size-sm;
    min-height: $btn-size-sm;
  }
}

.dragging {
  border-radius: $border-radius-md;
  background: rgba(255, 255, 255, 0.8);
}

.blockContent {
  min-height: $padding-pm;

  :global(.p-block-content) {
    height: 100%;
  }
}

.ignored {
  background: rgba(var(--color-gray-5), 0.7);;

  .blockContent {
    opacity: 0.7;
  }
}

.inline {
  > .col, > .blockWrapper {
    display: inline-block;
  }
}

.drawer {
  :global(.ant-drawer-content-wrapper) {
    max-width: 100%;
  }

  :global(.ant-drawer-header-title) {
    flex-direction: row-reverse;
  }

  :global(.ant-drawer-body) {
    padding: 0;
  }

  &.raw {
    :global(.ant-drawer-header) {
      border: none;
    }

    :global(.ant-drawer-body) > div:global(.ScrollbarsCustom) {
      height: calc(100vh - 119px) !important;
    }
  }
}

.horizontal {
  display: flex;
  flex-wrap: wrap; // wrap-reverse
}

.grid {
  display: grid;
}

.flex {
  display: flex;
}

.growInnerContent {
  > div {
    flex-grow: 1;
  }
}

.ts {
  max-width: 375px;
  //height: 667px;
}

.xs {
  max-width: 670px;
  //height: 375px;
}

.sm {
  max-width: $screen-md;
  //height: $screen-lg;
}

.md {
  max-width: $screen-lg;
  //height: $screen-md * 0.7;
}

.lg {
  max-width: $screen-xl;
  //height: $screen-md;
}

.xl {
  max-width: 100%;
  // height: auto;
}

@media only screen and (max-width: $screen-xs-max) {
  .container_wrapper {
    padding-left: $padding-sm + 2;
    padding-right: $padding-sm;
  }
  .container {
    iframe {
      max-width: 100%;
      height: calc(100vw * 0.5625)
    }
  }
}

