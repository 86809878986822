@import "../../../vars";

.modal_wrapper {
  overflow: auto;
  max-width: 90%;
  margin: $padding-sm 0;
  height: auto;

  :global(.ant-modal-title) {
    margin-right: 15px;
  }

  :global(.ant-modal-content) {
    overflow: hidden;
  }

  :global(.ant-modal-body) {
    font-size: $font-size-md;
    min-height: 200px;

    // padding-right: 0;
    padding: $padding-pm $padding-md $padding-pm;
  }

  p {
    img {
      height: auto !important;
    }
  }
}

.list_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

.small {
  :global(.ant-modal-body) {
    min-height: 50px;
  }
}

/*.tabs {
  &:global(.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab) {
    padding-left: $padding-xp;
    padding-right: $padding-pm;
  }

  :global(.ant-tabs-tab .icon) {
    margin-right: $padding-xp;
    width: 20px;
    display: inline-block;
    text-align: center;
  }

  :global(.ant-tabs-tabpane) {
    padding-bottom: $padding-sm;
    padding-top: $padding-sm;
  }

  :global(.ant-tabs-nav) {
    padding-bottom: $padding-sm;
    padding-top: $padding-sm;
  }
}*/

.card {
  box-shadow: $box-shadow-base;
  //margin-right: $padding-sm;
  //margin-bottom: $padding-xs * 2;
  display: inline-block;
  max-width: 240px;
  overflow: hidden;

  :global(.ant-card-body) {
    border-top: 1px solid var(--color-gray-4);
  }

  :global(.ant-card-cover) {
    overflow: hidden;
    height: 200px;
    width: 240px;
  }

  &:hover {
    outline: 2px solid var(--color-primary);
  }
}

.icon {
  font-size: 60px;
  color: var(--color-gray-5);
  margin-top: 25%;
  text-align: center;
  display: block;
}

@media only screen and (max-width: $screen-xs-max) {
  p iframe {
    max-width: 100%;
    height: calc((100vw * 0.9 - 48px) * 0.5625)
  }

  .modal_wrapper {
    max-width: 96%;
    margin: $padding-xs 0;

    //vertical-align: top;
    //height: calc(100vh - 16px);

    //:global(.ant-modal-content), :global(.ant-modal-body > div) {
    //  height: 100%;
    //}
    //
    //:global(.ant-modal-body) {
    //  padding-right: 0;
    //  height: calc(100% - 55px - 8px);
    //}

    //:global(.ant-tabs-content-holder) {
    //  overflow-y: auto;
    //}
  }

  .card {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;

    :global(.ant-card-cover) {
      height: 180px;
      width: 280px;
    }

    :global(.ant-card-meta-title) {
      font-size: 16px;
    }
  }

  .icon {
    margin-top: 16%;
  }
}