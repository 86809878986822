@import "../../../../../vars";

.creating_menu_wrapper {
  padding: 30px;
  width: 230px;
  position: absolute;
  height: auto;
  z-index: 1000;
}

.creating_menu {
  background-color: var(--color-white);
  padding: 7px 0;
  width: 100%;
  height: auto;
  border-radius: $border-radius-md;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);

  .creating_menu_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-radius: $border-radius-md;
    overflow: hidden;
    height: 30px;
    margin: 0 5px;
    padding: 0 5px;

    &:hover {
      background-color: #f8f8f8;
      cursor: pointer;
    }
  }

  .creating_menu_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 20px;
    margin-right: 5px;

    &:focus {
      outline: none;
    }

    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14px;
      height: 14px;

      & svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .creating_menu_label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;

    span {
      height: 20px;
    }
  }

  .creating_menu_line_break {
    width: 100%;
    height: 1px;
    margin: 5px 0;
    background-color: #DCDFE5;
  }
}

.creating_menu_item + .creating_menu_item {
  margin-top: 1px;
}
