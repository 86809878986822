@import "../../vars";

.modal_wrapper {
  max-width: 90%;
  height: auto;
  margin: $padding-sm 0;

  :global(.ant-modal-title) {
    margin-right: 15px;
  }

  :global(.ant-modal-content) {
    overflow: hidden;
  }

  :global(.ant-modal-body) {
    font-size: $font-size-md;
    min-height: 200px;
  }

  p {
    img {
      height: auto !important;
    }
  }
}

.small {
  :global(.ant-modal-body) {
    min-height: 50px;
  }
}

@media only screen and (max-width: $screen-xs-max) {
  p iframe {
    max-width: 100%;
    height: calc((100vw * 0.9 - 48px) * 0.5625)
  }

  .modal_wrapper {
    max-width: 96%;
    margin: $padding-xs 0;
  }
}