.effects_list_wrapper {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0 15px 0;
}

.extra_pre_fields {
    padding: 15px 10px 0;
    margin-bottom: 30px;
}

