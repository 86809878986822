@import "../../vars";

.container {
  padding-top: $padding-xs;
  font-size: $font-size-md;

  p img {
    height: auto !important;
  }
}

.author_wrapper {
  margin-top: $padding-xs;
  margin-bottom: $padding-pm;
}

.author_avatar {
  margin-right: $padding-ps;
}

.author_name {
  line-height: 40px;
}


@media only screen and (max-width: $screen-xs-max) {
  .container {
    p iframe {
      max-width: 100%;
      height: calc(100vw * 0.5625)
    }
  }
}

