@import "../../../vars";
@import "../../../mixins";

.rowsWrapper {
  width: 100%;
  margin-bottom: $padding-md;
}

.row {
  border: $border-width-md solid var(--color-gray-5);
}

.h_full {
  height: 100%;
}

.col {
  border: $border-width-md solid var(--color-gray-5);

  &:not(:global(.p-sec-wrap)) {
    height: 100%;
  }

  > :global(.ant-card-body) {
    height: 100%;
  }

  &:global(.bg-gray-light) {
    background-color: var(--color-gray-2);
  }

  &.h_full {
    height: calc(100% - 32px);
  }
}

.row, .col {
  :global(.ant-card-head) {
    padding: 0 $padding-xp;
  }

  &.editable {
    border-color: var(--color-primary-hover);

    :global(.ant-card-head) {
      background: var(--color-primary-light);
    }
  }

  :global(.ant-card-head-title) {
    padding: 0 !important;

    > span {
      display: inline-block;
      padding-top: $padding-xs / 2;
    }
  }
}

.row {
  > :global(.ant-card-body) {
    padding-bottom: $padding-xs / 4;
  }
}

.rowBtn .rowIcon {
  //font-size: $icon-size-lg;
  //color: var(--color-gray-7);
  //margin-bottom: $padding-xs;
}

.addButtons {
  margin-top: $padding-sm;

  button {
    margin-right: $padding-xp;
    margin-bottom: $padding-xp;
  }
}

