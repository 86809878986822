@import "../../../../../vars";

.wrapper_search_form {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    span {
        span {
            background-color: #f1f1f1;
            input {
                border: 1px solid #f1f1f1;
                height: 42px;
            }
            input:hover {
                border: 1px solid #f1f1f1 !important;
                box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.0);
            }
            input:focus {
                border: 1px solid #f1f1f1 !important;
                box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.0);
            }
            span {
                left: 0 !important;         // отступ слева у правой кнопки с лупой
                background-color: transparent;
                button {
                    width: 42px;
                    height: 42px;
                    border: 1px solid #f1f1f1;
                    &:hover {
                        // border-left-color: #f1f1f1 !important;
                        background-color: var(--color-gray-4);
                        box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.0);
                    }
                    &:focus {
                        background-color: var(--color-gray-4);
                        // border-left-color: #f1f1f1 !important;
                        box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.0);
                    }
                    span {
                        font-size: 21px !important;
                        color: #000;
                    }

                }
            }
        }
    }
}

.search_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: var(--color-white, #fff);
    border: 0;
    cursor: pointer;
    color: #000;
    border-radius: 6px;

    &:focus {
        outline: none;
    }

    & span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;

        & svg {
            width: 20px;
            height: 20px;
        }
    }
}

.show_search_field {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: var(--color-white, #fff);
    top: 55px;
    left: -155px;

    height: 40px;
    border-radius: 6px;
    border: 0;
    overflow: hidden;
    box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
    padding: 0;


    span {
        span {
            input {
                border: 0 solid transparent;
                margin-top: 2px;
            }
            input:hover {
                border: 0 solid transparent !important;
            }
            input:focus {
                border: 0 solid transparent !important;
                box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.0);
            }
            span {
                left: 2px !important;
                background-color: transparent;
                button {
                    width: 40px;
                    border: 0 solid transparent;
                    span {
                        font-size: 21px !important;
                        color: #000;
                    }

                }
            }
        }
    }
}

.vert_line {
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #f3f3f3;
    right: 38px;
}


@media only screen and (max-width: $screen-lg-max) {
    .hidden_lg {
        display: none !important;
    }
}

@media only screen and (min-width: $screen-xl-min) {
    .hidden_xl {
        display: none !important;
    }
    .graph_search_wrapper {
        width: 145px;
    }
}