@import '../../../../../../mixins';
@import '../../../../../../vars';

.wrapper_lists {
    width: 100%;
    margin-top: 30px;
    flex-grow: 1;
}

.row_list {
    @include flex_row(flex-start);
    width: 100%;
    height: 64px;
    border-bottom: 1px solid var(--color_dc);
    cursor: pointer;
    &:hover {
        background-color: var(--color-gray-2);
    }
}

.row_list:last-child {
    border: 0;
}

.row_icon {
    @include flex_row(center);
    width: 40px;
    height: 40px;
    margin-right: 10px;
    color: var(--color-gray-7);
    font-weight: bold;
}

.row_title {
    flex-grow: 1;
    font-weight: 500;
    font-size: 14px;
    height: 20px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.icon {
    width: 24px;
    height: 24px;
    color: var(--color-gray-7);
}
