@import '../../../mixins';
@import '../../../vars';

.test_button {
    padding-left: 5px;
    padding-right: 5px;
}

.wrapper_icon {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: -10px;
    float: right;

    &:global(.active) {
        color: var(--color-gray-9);
    }

    svg {
        margin-left: 6px;
        font-size: 22px;
    }
    span {
        padding-bottom: 2px;
    }
}