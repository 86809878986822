@import '../../../../../../mixins';
@import '../../../../../../vars';

.accordion_panel {
  background: var(--color-white);

  &:not(.block) {
    position: relative;
    width: 100%;
    border-radius: $border-radius-lg;
    margin-top: $padding-ps;
    padding: $padding-xp;
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.19);
  }

  &.block {
    margin-bottom: $padding-ps;
  }

  &.ignore {
    opacity: 0.8;
  }

  & .drop_arrow {
    width: 14px;
    height: 14px;
    color: var(--color-gray-9);
    font-weight: 500;
  }

  .accordion_panel_header {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    margin: 0;
    cursor: pointer;
    color: inherit;
    font-weight: inherit;

    .accordion_panel_header_icon {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 32px;
      height: 32px;
      margin-left: 10px;
    }

    .accordion_header_title {
      flex-grow: 1;
      margin-left: 11px;
      color: inherit;
      font-weight: inherit;
    }

    .accordion_panel_header_corner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transform: rotate(-90deg);
    }
  }

  .accordion_panel_content {
    padding: $padding-sm $padding-xs 0;
    position: relative;
  }
}

.restrict_content {
  cursor: help;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
}

.container_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  //background-color: var(--color_ae);
}

[data-typeblock="disabled"] {
  // background-color: var(--color_back_effect);
  background-color: var(--color-gray-5);
}

[data-typeblock="effect"] {
  // background-color: var(--color_back_effect);
  background-color: lighten(#8ED24E, 10%);
}

[data-typeblock="event"] {
  background-color: var(--color_back_event);
}

[data-typeblock="condition"] {
  background-color: var(--color_back_cond);
}

[data-typeblock="timer"] {
  background-color: var(--color_back_timer);
}

[data-typeblock="message"] {
  background-color: var(--color_back_message);
}

[data-typeblock="delete"] {
  background-color: lighten($color-danger, 10%);
}

