@import "../../vars";

.row {
  max-width: $screen-md;

  .col {
    // text-align: center;
    padding: 0 $padding-xs $padding-md;
  }
}

.card {
  //display: flex;
  //align-content: center;
  text-align: center !important;
  border-radius: 15px;
  border: 2px solid transparent;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.1);
  margin-right: 32px;
  margin-bottom: 32px;

  min-width: 150px;
  max-width: 300px;

  &:hover{
    border: 2px solid var(--color-primary);
  }
  &>div:first-child:not(:last-child) {
    padding: $padding-xs;
    min-height: 150px;
    width: 100%;
  }
}

.description {
  display: none; // TODO: remove it later
  color: var(--color-gray-8);
}

.link {
  img {
    max-width: 250px;
    width: 100%;
    transition: all 0.3s linear;

    &:hover {
      transform: scale(1.1);
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .card {
    margin-right: auto;
    margin-left: auto;
  }
}