@import "../../../../../vars";

.info_project {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 90px;
  min-height: 90px;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid var(--color-gray-4);
  // border-right: 1px solid var(--color-gray-5);

  .info_project_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 26px;
    width: 100%;

    .info_project_avatar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 50%;
      width: 40px;
      left: 40px;
    }

    .info_project_title {
      flex-grow: 1;
      margin-left: 15px;
      font-weight: 500;
      font-size: $font-size-lg;
      line-height: $font-size-lg;
    }

    .info_project_arrow {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      font-size: 24px;
    }
  }
}

.list_wrapper {
  padding: 20px 26px;
  border-bottom: 1px solid var(--color-gray-4);
}

.list_item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}


.list_avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.list_title {
  margin-left: 15px;
  font-weight: 400;
  font-size: $font-size-lg;
  line-height: $font-size-lg;
  color: var(--color-gray-8);

  &:hover, &:focus {
    color: var(--color-gray-9);
  }
}

