@import "../../../../../../vars";
@import "../../../../../../mixins";

.cpanel_header_wrapper {
  position: relative;
  background-color: var(--color-white, #fff);
  padding: 0 15px;
}

.cpanel_header_title_wrapper {
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cpanel_header_arrow {
  @include flex_row(center);
  width: 32px;
  height: 32px;
  font-size: 24px;
  margin-right: 15px;
  cursor: pointer;
}

.cpanel_header_icon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  color: #fff;

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  &[data-node-type="event"] {
    background: linear-gradient(90deg, #7367FF -7.14%, #B0AEFF 100%);
  }

  &[data-node-type="condition"] {
    background: linear-gradient(90deg, #F8AA34 0%, #FFDA95 100%);
  }

  &[data-node-type="message"] {
    background: linear-gradient(268.6deg, #7ac0ff .36%, #3697f2 96.95%);
  }

  &[data-node-type="timer"] {
    background: linear-gradient(90deg, #B071FF 0%, #D9A8FF 100%);
  }

  &[data-node-type="action"] {
    background: linear-gradient(90deg, #81C939 0%, #ADE680 100%);
  }

  &[data-node-type="note"], &[data-node-type="none"] {
    background: linear-gradient(90deg, #bfbfbf 0%, #dcdfe5 100%);
  }

  //&[data-node-type="none"] {
  //  background: linear-gradient(90deg, #ff5858 0%, #ffb1b1 100%);
  //}
}

.cpanel_header_title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  height: 32px;
  padding-left: 12px;

  .text {
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
  }

  .node_id {
    position: relative;
    display: block;
    width: auto;
    font-size: 12px;
    line-height: 12px;
    color: var(--color-gray-7);
    margin-top: 3px;
  }
}

