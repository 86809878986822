@import "../../../../vars";

.control_panel_wrapper {
  top: 0;
  right: -$drawer-width;
  width: $drawer-width;
  height: 100vh;

  padding-bottom: 50px;
  position: fixed;
  background-color: var(--color-white);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
  transition: all 300ms;
  z-index: 110;
}

.control_panel_inline {
  // strobing
  background-color: var(--color-white);
  position: relative;

  :global(.container-catalog-wrapper .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list) {
    width: auto;
  }
}

.control_panel {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  a {
    text-decoration: underline;
    color: inherit;

    &:hover, &:focus {
      text-decoration: none;
      color: var(--color-primary);
    }
  }
}

.dash_board_wrapper {
  position: relative;
  // border-bottom: 1px solid var(--color-gray-5);
  // padding: 0 15px 30px;
  padding: 0 15px 12px;
}
.note_title_wrapper {
  padding: 0 15px 30px;
}

.control_panel_mini {
  height: 100vh;
  padding-bottom: 20px;
}


@media only screen and (max-width: $screen-xs-max) {
  .control_panel_wrapper {
    right: -100%;
    width: 100%;
  }
  .control_panel_mini {
    height: auto;
  }
}