@import "../../../../../vars";

.buttons_zoom {
    position: absolute;
    top: 70px;
    left: -50px;
}

@media only screen and (max-width: $screen-xs-max) {
    .buttons_zoom {
        top: 15px;
    }
}