@import '../../../../../../../vars';
@import '../../../../../../../mixins';

.wrapper {
  padding: $padding-xs 0 $padding-pm;
}

.row {
  display: flex;
  // border: 1px dotted #ccc;
}

.button {
  font-size: $font-size;
  @include text-ellipsis;
  padding: 5px 1rem;
  margin: 0 .5rem .7rem 0;
  text-align: center;
  flex-grow: 1;
  cursor: grab;
  flex-basis: 100%;
  flex-basis: -moz-available;

  span {
    display: inline;
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }
}

.empty {
  font-size: $font-size;
  border: 1px dashed var(--color-gray-7);
  margin-bottom: .7rem;

  span, &.dragging {
    color: transparent;
  }

  &:hover {
    border: 1px solid var(--color-gray-7);
  }

  :global(.anticon) {
    font-size: $icon-size-md - 1;
  }
}

.add {
  font-size: $font-size;
  border: 2px dotted transparent;

  &:hover {
    border: 2px dotted var(--color-gray-7);
  }
}


