@import '../../../../../../mixins';

.buttons_effects {
    @include flex_row(space-between);
}

.button_effect {
    @include flex_row(center);
    flex-grow: 1;
    color: var(--color_ff) !important;
    background-color: var(--color-primary) !important;
    border: 0;
    outline: none;
    border-radius: 6px;
}

.wrapper_effects {
    @include flex_row(space-between);
    align-items: flex-start;
    flex-grow: 1;
    & > div {
        margin-right: 15px;
    }
    div:last-child {
        margin-right: 0;
    }
}

.effect_block {
    flex-grow: 1;
    width: 100%;
}

.effect_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.effect_title {

}
