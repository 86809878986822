@import "../../../../../vars";

.node_buttons_wrapper {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-end;

  //transition: all 300ms;
  position: absolute;

  right: 4px;
  //top: 0;
  top: -38px;

  > div {
    transition: 300ms visibility 0ms, opacity 300ms;
    //transition: opacity 300ms;
    visibility: hidden;
    display: none;
    opacity: 0;
  }
}


:global(.react-flow__node.selected:not([selected="true"])) {
  .node_buttons_wrapper {
    //transition: all 300ms;
    top: -38px;

    > div {
      transition: 300ms visibility 0ms, opacity 300ms;
      visibility: visible;
      display: flex;
      opacity: 1
    ;
    }
  }
}
