@import '../../../../../../mixins';

.block_buttons {
    @include flex_row_wrap(flex-start);
}

// ----------------------------------------------------------------
.small_button {
    @include button_simple_gray__small();
    background-color: var(--color_f5);
    border: 1px solid var(--color_d9);
    transition: all 0ms;
}

[data-active="true"] {
    background-color: var(--color-primary) !important;        // чтобы hover не перекрашивал background активной кнопки
    border-color: var(--color-primary) !important;
    color: #fff;
}
// ----------------------------------------------------------------

