@import "../../vars";
@import "../../mixins";

.collapse {
  @include section_block_card;
  overflow: hidden;

  .collapse_title {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-gray-7);

    .collapse_icon {
      color: var(--color-gray-8);
      display: flex;
      align-items: center;

      font-size: 22px;
      padding: 16px 0 17px 40px
    }

    h3 {
      padding: 16px 16px 16px 16px;
      font-weight: 500;
      margin: 0;
    }
  }

  .active {
    .collapse_title {
      h3, .collapse_icon {
        color: var(--color-gray-9);
      }
    }
  }
}

.inline_card {
  // padding: $padding-md;
  .collapse {
    box-shadow: none;
    // border: 1px solid var(--color-gray-5);
    .collapse_title h3 {
      font-weight: 400;
    }
    .collapse_icon {
      padding-left: $padding-xs;
    }
    > div:last-child > div {
      padding: 0 $padding-sm $padding-sm;
    }

    :global(button.btn-collapse) {
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .collapse {
    box-shadow: none;

    .collapse_title {
      h3, .collapse_icon {
        color: var(--color-gray-7);
      }
    }
  }
}


