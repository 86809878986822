@import "../../../vars";

//.card {
//  padding: 30px 40px;
//  box-shadow: 0 8px 35px rgb(0 0 0 / 7%);
//  border-radius: $border-radius-lg;
//  overflow: hidden;
//
//  h2 {
//    font-size: $font-size-h2 - 4;
//    :global(.anticon) {
//      margin-right: $padding-xs;
//    }
//  }
//  :global(.ant-typography-secondary) {
//    font-weight: 300;
//  }
//}

// orig: 270px; 150px;
// 1366: 302px; 167px;
// 1920: 285px; 158px;

.card {
  box-shadow: $card-shadow;
  border-radius: $border-radius-xl;
  margin: 0 $padding-ps $padding-ps 0;
  width: 285px; // 270px
  overflow: hidden;
  display: inline-block;
  border: none;

  :global(.ant-card-cover) {
    margin: 0;
  }

  .photo_wrapper {
    background-image: $brand-gradient;
    position: relative;
    background-position: center center;
    background-size: cover;
    height: 158px; // 150px
  }

  //:global(.ant-card) {
  //  filter: drop-shadow(0px 10px 35px rgba(0, 0, 0, 0.1));
  //}

  :global(.ant-card-body) {
    padding: $padding-ps $padding-ps $padding-ps;
  }

  :global(.ant-card-actions) {
    display: flex;
    padding: 0 $padding-ps $padding-ps;
    margin-top: -4px;
    li {
      margin: 0;
    }
  }

  :global(.ant-card-actions) {
    border-top: none;
  }

  .title {
    margin-bottom: $padding-xp;
    font-size: $font-size-md;
    line-height: $font-size-md * 1.2;
    height: $font-size-md * 2.4;
    font-weight: 500;
    overflow: hidden;

    display: grid;
    align-items: center;
  }

  &:hover, &.active {
    outline: 2px solid var(--color-primary);
  }
}

.open_icon {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-white);
  z-index: 300;
  opacity: 0;
  transition: opacity 200ms linear;
}

.photo_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  background: transparent;
  transition: background-color 300ms ease-out;
}

.photo_wrapper:hover {
  .open_icon {
    opacity: 1;
    transition: opacity 300ms linear;
  }

  .photo_overlay {
    background: var(--color-primary);
  }
}

.price {
  color: var(--color-gray-8);
}

.type {
  color: var(--color-gray-7);
}


@media only screen and (max-width: $screen-xs-max) {
  .card {
    width: 100%;
    .photo_wrapper {
      height: 195px;
    }
  }
  //.card {
  //  padding: 20px 25px;
  //
  //  &.flat_on_xs {
  //    padding: 15px 0 30px;
  //    box-shadow: none;
  //    border-radius: 0;
  //  }
  //}

}

