@import "../../vars";

$button-size: 70px;

// player

.player_wrapper {
  position: relative;
  video {
    border-radius: $border-radius-lp;
    overflow: hidden;
  }
}

.play_pause_button {
  position: absolute;
  left: 50%;
  top: 50%;
}

.play_pause_icon {
  margin: -$button-size 0 0 (-$button-size);
  font-size: $button-size;
  color: var(--color-white);
  opacity: 0.7;

  animation: glowing 2000ms infinite;

  @keyframes glowing {
    0% { transform: scale(1); }
    40% { transform: scale(2)}
    100% { transform: scale(1) }
  }
}

.icon_dark {
  color: var(--color-gray-9);
  opacity: 0.5;
}

.video_container {
  width: 100%;
  height: auto;
}

// modal

.modal_wrapper {
  width: 90%;
  height: auto;

  :global(.ant-modal-content) {
    overflow: hidden;
  }

  :global(.ant-modal-body) {
    padding: 0;
    line-height: 0;
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .play_pause_button {
    margin-top: -$button-size/3;
  }
  .play_pause_icon {
    margin: (-$button-size/2) 0 0 (-$button-size/2);
    font-size: $button-size/2;
  }
}