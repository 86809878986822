@import '../../mixins';
@import '../../vars';


.button {
  @include flex_row(center);
  max-width: 110px;
  width: 100%;

  .button__text {
    // font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-lg;
    line-height: 24px;
    text-align: center;
    color: var(--color-gray-7);
  }
}


.input__inner {
  display: flex;
  padding: 2px 8px 6px 8px;
  align-items: center;
  justify-content: space-between;
  max-width: 247px;
  width: 100%;
  // border-bottom: 1px solid var(--color-gray-6);

  border-bottom: 2px solid var(--color-primary);

  .input {
    line-height: $font-size-lg;
    background-color: transparent;
    border: none;
    outline: none;
    width: 150px;

    &::placeholder {
      // font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--color-gray-6);
    }
  }
}

:global(.full-width) .input__inner {
  max-width: 90%;
}

:global(.large){
  .input__inner .input{
    line-height: 26px;
    font-size: $font-size-md;
  }
  button {
    font-size: $font-size-md;
    height: $font-size-lg * 2;
  }
}

.input__search {
  margin-right: 15px;
  color: var(--color-gray-6);
  line-height: $font-size-lg;
}

.input__search_only {
  margin-right: 3px;
  color: var(--color-gray-6);
  line-height: $font-size-lg;
}

.input__close {
  color: var(--color-gray-6);
  line-height: $font-size-lg;
}

@media only screen and (max-width: $screen-xs-max) {
  .input__inner {
    border-bottom: 2px solid var(--color-gray-4);

    &.focus {
      border-bottom: 2px solid var(--color-primary);
    }

    .input {
      width: 100%;
    }
  }
}