// common vars - another var style
$color-success: #67C23A;
$color-warning: #EFB662;
$color-gold:  #FFAA2E;
$color-note: #FFE55C;
$color-danger: #F56C6C;
$color-info: #03a9f4;
$color-landing: #F6F7F9;
$color-purple: #8D5ED8;

$color-light-button: #E6EEFF;
$color-gray-blue: #DCDFE5;

$transparent: transparent;

$lighten-1: 10%;
$darken-1: 10%;

$color-shadow-4: rgba(0, 0, 0, 0.19);
$color-shadow-3: rgba(0, 0, 0, 0.15);
$color-shadow-2: rgba(0, 0, 0, 0.1);
$color-shadow-1: rgba(0, 0, 0, 0.07);
$color-shadow-0: rgba(0, 0, 0, 0.01);

$card-shadow: 2px 3px 7px $color-shadow-3;

$box-shadow-base:
  0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

// $brand-simple-color: rgba(141, 94, 216, 1);
$brand-gradient: linear-gradient(67deg, rgba(236, 159, 89, 1) 0%, rgba(203, 88, 196, 1) 50%, rgba(141, 94, 216, 1) 85%, rgba(117, 98, 224, 1) 100%);

$font-size: 14px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 21px;

$font-size-h1: ceil($font-size*2.428);
$font-size-h2: ceil($font-size*1.71);
$font-size-h3: ceil($font-size*1.285);
$font-size-h4: ceil($font-size*1.14);
$font-size-h5: ceil($font-size*1);

$icon-size-md: 18px;
$icon-size-lg: 24px;
$icon-size-xl: 48px;

$btn-size-sm: 28px;
$btn-size-md: 35px;

$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius-lg: 8px;
$border-radius-lp: 10px;
$border-radius-xl: 14px;

$border-width-base: 1px;
$border-width-sm: 1px;
$border-width-md: 2px;
$border-width-lg: 4px;

$padding-none: 0;
$padding-xs: 8px;
$padding-xp: 10px; // post extra small
$padding-ps: 18px; // pre small
$padding-sm: 24px;
$padding-pm: 30px; // pre medium
$padding-md: 40px;
$padding-pl: 60px; // pre large
$padding-lg: 70px;
$padding-xl: 100px;

$transition-time: 200ms;
$transition-style: ease;

$left-menu-width: 340px;
$dialog-drawer-width: 400px;
$drawer-width: 500px;

// Media queries breakpoints
// $screen-xs and $screen-xs-min is not used in Grid
// smallest break point is $screen-md
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
// 👆 Extra small screen / phone

// 👇 Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// provide a maximum
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);