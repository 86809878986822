@import "../../../vars";
@import "../../../mixins";

//.container, .leftPanel {
//  display: flex;
//}

.listWrap {
  width: $left-menu-width;
  border-right: 1px solid var(--color-gray-4);
  box-shadow: 0 0 10px $color-shadow-2;
  z-index: 2;
}

.listDate {
  color: var(--color-gray-6);
  font-size: $font-size-sm;
  line-height: 25px;
  font-weight: normal;
}

.search {
  margin: 4px $padding-ps 4px 48px;
  width: 100% !important;

  > :global(.ant-input-group) {
    > :global(.ant-input):first-child {
      border-radius: $border-radius-md;

      &:hover, &:focus {
        border-right-width: $border-width-md;
      }
    }

    > :global(.ant-input-group-addon):last-child {
      z-index: 2;
      position: absolute;
      left: calc(100% - 42px);
      border: none;
      background: transparent;
      display: inline-block;
      padding-top: 2px;

      :global(.ant-input-search-button) {
        border: none;
        height: 38px;
        background: radial-gradient(rgba(255, 255, 255, 0.9), transparent);
        border-radius: 50%;

        &:hover {
          color: var(--color-gray-8);
        }
      }
    }
  }
}

.listItem {
  border-top: 1px solid var(--color-gray-4);
  padding-left: $padding-ps;
  padding-right: $padding-ps;

  h4 {
    font-weight: 400;
    white-space: nowrap;

    span:global(.nowrap) {
      display: inline-block;
      padding-right: 50px;
    }
  }

  :global(.ant-list-item-meta-description) {
    font-size: $font-size-sm;
    line-height: 1.2em;
    @include text-ellipsis;
  }

  &.unread {
    background-color: var(--color-gray-3);
  }

  &.active {
    background-color: var(--color-primary-bg);
  }

  &:hover {
    background-color: var(--color-primary-light);
  }
}

.emptyIcon {
  font-size: 25px;
  margin: 70px 0 10px;
}

// ========= Messages List =========

.messagesWrap {
  background: var(--color-gray-3);
  flex-grow: 1;
  z-index: 1;

  .header {
    h3 {
      margin: 3px 0 0 20px;
      font-weight: 400;
    }
  }

  > div:not(.header) {
    height: 100%;
  }

  :global(.ScrollbarsCustom-Content) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  :global(.ScrollbarsCustom.result) {
    :global(.ScrollbarsCustom-Content) {
      justify-content: flex-start;
    }
  }

  :global(.ScrollbarsCustom-Track) {
    background: none !important;
  }

  :global(.ScrollbarsCustom-Thumb ) {
    background: rgba(0, 0, 0, 0.1) !important;
  }
}

.messages {
  overflow-y: auto;
  padding: $padding-md;
}

.date, .name {
  color: var(--color-gray-7);
  font-size: 12px;
}

.name {
  margin: 4px;
}

.message {
  margin-bottom: 4px;

  &.danger, &.system, &.day {
    margin-bottom: $padding-xp;
  }

  &.day {
    color: var(--color-gray-7);
  }

  &.system {
    color: var(--color-gray-8);
  }

  &.danger {
    color: var(--color-danger);
  }

  :global(.message) {
    min-width: 100px;
    max-width: 400px;
    border-radius: 0 $border-radius-xl $border-radius-xl 0;
    padding: $padding-xs $padding-ps;
    background: var(--color-white);
    box-shadow: 0 0 15px $color-shadow-1;
    overflow: hidden;

    > span {
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  //:global(.first) {
  //  border-radius: $border-radius-xl $border-radius-xl $border-radius-xl 0;
  //}

  &:global(.align-left) + &:global(.align-right) {
    margin-top: $padding-ps;
  }

  :global(.last) {
    border-radius: 0 $border-radius-xl $border-radius-xl $border-radius-xl;
  }

  :global(.time) {
    padding-left: $padding-xs;
  }

  :global(.out) {
    background: var(--color-primary);
    color: var(--color-white);

    code, pre {
      background: #3F57AC;
      color: var(--color-white);
      white-space: pre-wrap;
      word-break: break-word;
    }

    pre > code {
      background: none;
      padding: 0;
    }

    *::selection {
      background: var(--color-white);
      color: var(--color-gray-8);
    }

    a {
      color: var(--color-white);
      text-decoration: underline;

      &:hover {
        color: var(--color-gray-3);
        text-decoration: none;
      }
    }

    :global(.ant-btn-text), :global(.color-secondary) {
      color: var(--color-gray-2);
    }

    :global(.ant-btn-text):hover, :global(.ant-btn-text):focus {
      color: var(--color-gray-4);
      text-decoration: underline;
    }

    .date {
      color: var(--color-gray-5);
    }
  }

  :global(.note) {
    background: #fded9b;
  }

  :global(.attachments) {
    min-width: 200px;
    max-width: 85%;
  }

  :global(.attachment) {
    margin-bottom: $padding-xs;

    :global(.ant-btn) {
      min-height: auto;

      > span {
        display: inline;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  :global(.out), :global(.note) {
    border-radius: $border-radius-xl 0 0 $border-radius-xl;

    &:global(.first) {
      border-radius: $border-radius-xl $border-radius-xl 0 $border-radius-xl;
    }
  }
}

.enable {
  max-width: 700px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.modal {
  img {
    max-height: calc(100vh - 96px);
  }

  :global(.ant-modal-close-x) {
    background: var(--color-white);
    border-radius: 50%;
  }
}

.paddingPsHorizontal {
  padding: 0 $padding-sm;
}

.form {
  // background: var(--color-white);
  //box-shadow: 0 0 10px $color-shadow-1;

  padding: $padding-sm;
  gap: $padding-xp;
  align-items: flex-end;
  z-index: 4;
  position: relative;
}

.textarea {
  flex-grow: 1;
}

.submit {

}

.drawer {
  width: $dialog-drawer-width;
  border-left: 1px solid var(--color-gray-4);
  box-shadow: 0 0 10px $color-shadow-1;
  z-index: 3;
}

@media (max-width: $screen-xs-max) {
  .container {
    flex-direction: column;
  }

  .opened {
    .listWrap, &.leftPanel {
      display: none;
    }
  }
  .closed {
    .listWrap {
      padding-top: 85px;
      box-shadow: none;
      width: 100%;
    }

    .messagesWrap {
      display: none;
    }
  }

  .search {
    margin-left: $padding-ps;
  }
}
