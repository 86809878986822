@import "vars";

@mixin ellipsis($rows:1) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: $rows;
    line-clamp: $rows;
   -moz-box-orient: vertical;
    box-orient: vertical;
}

@mixin text-ellipsis {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


@mixin border_block {
    border-radius: $border-radius-md;
    border: 2px solid var(--color_dc);
}

@mixin section_block_card {
    box-shadow: 0 8px 35px rgba(0, 0, 0, 0.07);
    border-radius: $border-radius-xl;
    border: none;
}

@mixin offset_height($type:default) {
    $height: 34px;
    @if $type == small {
        $height: 26px;
    } @else if $type == large {
        $height: 42px;
    }

    height: $height;
}

@mixin wrapper_input_counter($type:default) {
    $height: 34px;
    @if $type == small {
        $height: 26px;
    } @else if $type == large {
        $height: 42px;
    }

    @include flex_row(space-between);
    width: 100%;
    height: $height;
    padding: 0 8px;
    background: #FFFFFF;
    outline: none !important;
    @include border_block;

    &:hover {
        border-color: var(--border-color-hover);
    }
}

@mixin count_title {
    font-weight: 400;
    font-size: 16px;
    color: #000;
}

@mixin block($x, $y, $b:6) {
    width: $x+px;
    min-width: $x+px;
    height: $y+px;
    min-height: $y+px;
    border-radius: $b+px;
}

@mixin flex_row($justify, $align: center) {
    display: flex;
    flex-direction: row;
    justify-content: $justify;
    align-items: $align;
}

@mixin flex_row_wrap($property) {
    @include flex_row($property);
    flex-wrap: wrap;
}

@mixin flex_col($property) {
    display: flex;
    flex-direction: column;
    align-items: $property;
}

@mixin button_simple_gray {
    background-color: var(--color_f1);
    color: var(--color-gray-9);
    border-radius: 6px;
    white-space: nowrap;
    border: 2px solid transparent;
    outline: none;
    cursor: pointer;

    &:hover {
        outline: none;
        background-color: var(--color_ea);
        //border: 2px solid var(--color_dc);
    }
    &:focus {
        outline: none;
        border: 2px solid var(--color_dc);
        background-color: var(--color_dc);
    }
}

@mixin button_simple_gray__large {
    font-weight: 500;
    font-size: 16px;
    padding: 10px 20px;
    margin-bottom: 20px;
    @include button_simple_gray();
    &:not(:last-child) {
        margin-right: 15px;
    }
}

@mixin button_simple_gray__middle {
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    padding: 8px 16px;
    margin-bottom: 15px;
    @include button_simple_gray();
    &:not(:last-child) {
        margin-right: 12px;
    }
}

@mixin button_simple_gray__small {
    height: 26px;
    font-weight: 400;
    font-size: 14px;
    padding: 2px 10px;
    margin-bottom: 12px;
    @include button_simple_gray();
    &:not(:last-child) {
        margin-right: 8px;
    }
}

@mixin line_clamp($property) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $property;
    line-clamp: $property;
    -moz-box-orient: vertical;
    box-orient: vertical;
}

@mixin max_width($width) {
    max-width: $width+px;
    width: 100%;
}

@mixin block44($back:var(--color_ff)) {
    width: 100%;
    height: 44px;
    min-height: 44px;
    border-radius: 6px;
    background-color: $back;
}

@mixin title_fw($fs, $fw) {
    font-size: $fs+px;
    line-height: ($fs+5)+px;
    font-weight: $fw;
}

@mixin check_box_type($type:default) {
    $width: 16px; $height: 16px; $lastheight: 24px; $fontsize: 16px; $padleft: 8px;
    @if $type == small {
        $width: 13px; $height: 13px; $lastheight: 18px; $fontsize: 14px; $padleft: 5px;
    } @else if $type == large {
        $width: 20px; $height: 20px; $lastheight: 26px; $fontsize: 18px; $padleft: 8px;
    }

    @include flex_row(flex-start);
    font-size: $fontsize;
    flex-grow: 1;
    & > span:first-child {
        @include flex_row(center);
        width: $height; height: $height;
        & > span {                          // .ant-checkbox-inner
            width: $height; height: $height;
            outline: none !important;       // !important <= состояние focus() не должно переоределять outline
        }
    }
    & > span:last-child {
        font-size: $fontsize;
        flex-grow: 1;
        height: $lastheight !important;
        padding-left: $padleft;
        & > div {
            font-size: $fontsize;
            height: $lastheight;
        }
    }
}