@import "../../vars";
@import "../../mixins";

.card {
  padding: 30px 40px;
  box-shadow: 0 8px 35px rgb(0 0 0 / 7%);
  border-radius: $border-radius-lg;
  overflow: hidden;

  .btn_edit {
    background: var(--color-gray-4);
    color: var(--color-gray-9);
    margin-left: 15px;
    border: none;

    svg {
      font-size: $font-size-md;
    }

    &:hover, &:focus, &:active {
      background: var(--color-gray-3);
    }
  }

  .row_mob {
    //justify-content: space-between;
    //align-items: flex-start;

    //.img_title {
    //  display: flex;
    //}
    .btn_edit {
      font-size: $font-size-xl;
    }
  }


  .image {
    padding-right: 25px;

    img {
      border-radius: $border-radius-xl;
      width: 100%;
      height: auto;
      max-width: 178px;
    }

  }

  .offer {
    max-width: 70%;

    .offer_text {
      padding-bottom: $padding-xp;
    }

    h2 {
      color: var(--color-gray-9);
    }
  }
}


.status {
  @include flex_row(flex-start);
  display: inline-flex;
  padding: $padding-xs/2 $padding-xp;
  font-size: $font-size-xs;
  position: relative;
  border-radius: $border-radius-sm;
  height: 25px;

  svg {
    margin-right: $padding-xs;
    font-size: $font-size-md;
  }
}

.content {
  padding: 10px 40px 30px;
}

.progress_card {
  padding: 30px 40px;
  box-shadow: 0 8px 35px rgb(0 0 0 / 7%);
  border-radius: $border-radius-lg;

  .currencies {
    margin-top: 30px;
    padding: 0;

    .card_text {
      line-height: $font-size-md;
      color: var(--color-gray-8);
      margin-top: 13px;
    }

    .progress_title {
      display: flex;

      .icon {
        font-size: 21px;
        line-height: 21px;
        padding-right: 8px;
      }

      .number {
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .card {
    padding: 30px 40px 10px;

    .row_mob {
      margin-bottom: $padding-sm;
    }

    .image {
      padding-right: $padding-xp+3;

      img {
        border-radius: 50%;
        max-width: 100%;
        width: auto;
        height: 42px;
      }
    }

    .offer {
      display: flex;
      align-items: center;
      height: 42px;
      max-width: none;
      overflow: hidden;

      h3 {
        color: var(--color-gray-9);
        font-size: 18px;
        line-height: 21px;
        display: contents;
      }
    }

    .offer_text {
      margin-bottom: 9px;
    }
  }

  .progress_card {
    border-bottom: 1px solid var(--color-gray-4);

    .col {
      margin-bottom: $padding-md;
    }
  }
}

