.block_mode_wrapper {
    height: 100vh * 0.65;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 30px;
}

.wrapper_content_sections {
    position: relative;
    padding: 20px;
}

.wrapper_sections_list {
    width: 100%;
    position: relative;
    margin-top: 0;
    // margin-top: 30px;
}

