@import "../../../../vars";
@import "../../../../mixins";

$color-stroke-hovered: var(--color-primary-lighten);
$stroke-sensibility-width: 10;

// $flow-stroke-color: #909090;
// $flow-handle-color: #888888;

// edges styles
.react-flow__edge .react-flow__edge-path {
  opacity: 0.9;
  stroke: var(--color-gray-7);
  stroke-width: 2px;
  cursor: pointer;

  &.success {
    stroke: $color-success;
  }

  &.failure, &.danger {
    stroke: $color-danger;
  }

  &.warning {
    stroke: $color-warning;
  }

  &.arrow {
    fill: var(--color-gray-7);
    stroke-width: 0.5px;
    stroke-dasharray: 0;

    &.success {
      fill: $color-success;
    }

    &.failure, &.danger {
      fill: $color-danger;
    }

    &.warning {
      fill: $color-warning;
    }
  }

  &.handle {
    stroke-width: $stroke-sensibility-width;
    stroke: transparent !important; // for hover and selected to
  }


}

.react-flow__edge.selected .react-flow__edge-path {
  stroke: var(--color-primary);

  &.arrow {
    fill: var(--color-primary);
  }
}

.react-flow__edge:hover .react-flow__edge-path {
  stroke: $color-stroke-hovered;

  &.arrow {
    fill: $color-stroke-hovered;
  }
}

.react-flow__edge {
  .edge-label-wrapper {
    visibility: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    .buttons-wrapper {
      .button {
        //background: none;
        border: none;
        color: var(--color-gray-7);
        opacity: 0.8;

        &:hover {
          // color: $color-danger;
          opacity: 1;
        }
      }
    }
  }
}


.react-flow__edge:hover {
  .edge-label-wrapper {
    visibility: visible;

    .edge-label {
      background: #F3F3F3;
      border-radius: $border-radius-md;
      //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      padding: 5px 15px;
      text-align: center;
      display: inline-block;
      max-width: 100%;
      width: auto;
    }
  }
}



