@import "../../../../vars";

.note_preset_primary {
  background: var(--color-primary);
  color: var(--color-white);
}

.note_preset_success {
  background: $color-success;
  color: var(--color-white);
}

.note_preset_danger {
  background: $color-danger;
  color: var(--color-white);
}

.note_preset_inverse {
  background: var(--color-gray-8);
  color: var(--color-white);
}

.note_preset_default {
  background: var(--color-white);
  //color: var(--color-gray-9);
}

span.note_preset_default {
  border: 2px solid #C6C8CE;
}

.note_preset_note {
  background: $color-note;
}
