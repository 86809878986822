.left_bottom{
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 100;
}

.bottom_left{
    position: absolute;
    bottom: 15px;
    left: 70px;
    z-index: 100;
}