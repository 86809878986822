@import "../../../../../../vars";

.node_operations_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    width: 35px;
    height: 35px;
    background-color: var(--color-gray-4);
    border: none;

    &:hover, &:focus {
        background-color: var(--color-gray-5);
        color: var(--color-gray-9);
    }
    &:after {
        // disable animated outline
        display: none;
    }
}

.node_operations_menu {
    background-color: var(--color-white);
    border-radius: $border-radius-md;
}

.node_operations_item_wrapper {
    height: 24px;
    border-radius: 6px;
    &.disabled {
        color: var(--color-gray-6);
    }
}

