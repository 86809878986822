@import '../../../mixins';
@import '../../../vars';

.header_input {
  // {width: '700px', maxWidth: '90%'}:
  width: 700px;
  max-width: 100%;
}

.header_edit_icon {
    margin-bottom: -1px;
    margin-right: 15px;
    vertical-align: sub;
}

.header_title {
  font-weight: 500;
}

.header_input_ok {
  margin-left: -40px;
  position: absolute;
  height: 42px;
  width: 40px;
}

@media only screen and (max-width: $screen-xs-max) {
  .affix {
    z-index: 30;
    position: fixed;
    width: 100%;
    bottom: 0;
    box-shadow: 0 0 15px $color-shadow-3;

    :global(.page-container-horizontal-padding.has-banner) {
      padding: 0;
    }

    :global(header.margin-bottom-md) {
      margin-bottom: 0;
    }

    :global(.ant-page-header) {
      padding: $padding-xs $padding-sm;
    }

    :global(.ant-page-header-heading-title) {
      :global(.anticon) {
        font-size: $icon-size-md;
        margin-bottom: 2px;
      }

      :global(h3.ant-typography) {
        font-size: $font-size-md;
      }
    }
  }

  .header_input {
    font-size: $font-size-md;
  }

  .header_input_ok {
    margin-left: 0px;
    position: absolute;
    height: 42px;
    width: 40px;
  }
}
