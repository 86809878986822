@import "../../../../vars";

.wrapper {
  //display: none;

  position: fixed;
  bottom: $padding-pm;
  right: $padding-pm + $padding-xp;
  z-index: 1000;
  width: 90px;
  height: 90px;
  cursor: pointer;
  border-radius: 100%;

  > img {
    opacity: 0.85;
    border-radius: 100%;
    box-shadow: $box-shadow-base;
  }

  &:hover {
    > img {
      opacity: 1;
    }
  }
}

.popup {
  position: absolute;
  width: 270px;
  bottom: calc(100% + 6px);
  background: var(--color-white);
  right: $padding-pl;
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-xl $border-radius-xl 0 $border-radius-xl;
  overflow: hidden;

  h4 {
    font-weight: normal;
  }

  :global(.ant-list-item) {
    padding-left: $padding-ps;

    &:hover {
      background: var(--color-gray-2);
    }
  }
}

@media (max-width: $screen-xs-max) {
  .wrapper {
    bottom: 20px;
    right: 25px;
    width: 45px;
    height: 45px;
  }
  .popup {
    right: $padding-pl / 2;
  }
}
