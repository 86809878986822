@import "../../../../mixins";
@import "../../../../vars";

// groups

.ports_wrapper {
  @include flex_row_wrap(center);
  position: absolute;
  top: 15px;
}

.inputs_ports {
  left: -20px;
  //right: -20px;
}

.outputs_ports {
  right: -20px;
  //top: inherit;
  //bottom: 15px;
}

.branching_ports, .reactions_ports, .buttons_ports {
  right: -35px;
}

// ports

.port {
  @include flex_col(center);
  cursor: pointer !important;
  background: var(--color-white);
  border: 2px solid var(--color-gray-5);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
  opacity: 0.9;
  height: 20px;
  width: 20px;
}

.target_port {
  border-radius: 2px;
}

.source_port {
  border-radius: 50%;
}

.port_has_edge {
  background: var(--color-gray-7);
  border-color: var(--color-white);
  &.port_success {
    background: $color-success;
  }
  &.port_danger {
    background: $color-danger;
  }
  &.port_warning {
    background: $color-warning;
  }
}


// buttons

.node_buttons_wrapper {
  position: relative;
  padding: 0 $padding-xp $padding-xp;
  margin-top: -7px;
  border-radius: 0 0 6px 6px;
}

[data-ignored="true"] {
  .ports_wrapper, .node_buttons_wrapper{
    opacity: 0.5;
  }
}

.node_button_wrapper {
  @include flex_row(center);

  border: 1px solid var(--color-gray-5);
  box-sizing: border-box;
  position: relative;

  border-radius: 8px;
  line-height: 12px;
  padding: 3px 10px;
  width: 100%;
  height: 24px;

  &:not(:last-child) {
    margin-bottom: $padding-xs / 2;
  }

  .node_button {
    @include flex_row(center);
    width: 100%;
    position: relative;
  }

  .node_button_text {
    white-space: nowrap;
    width: 100%;
    max-width: 200px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //-webkit-line-clamp: 1;
  }
}

:global(.react-flow__handle[selected="true"]) {
  background-color: var(--color-primary);
  border-color: var(--color-white);
}

// default selection method is useless (but can be useful for diagnostics)
//:global(.react-flow__handle.connecting) {
//  background-color: $color-warning;
//  border-color: var(--color-white);
//}