@import "../../../../../../vars";

.wrapper_button_action {
    width: 32px;
    height: 32px;
    margin-left: 12px;
    border-radius: $border-radius-md;
    background: var(--color-gray-4);

    &:hover, &:focus {
        background: var(--color-gray-5);
    }

    .action_content {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .three_point {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        line-height: 20px;
        font-weight: 500;
    }
}

