@import "../../../vars";

.card {
  padding: 30px 40px;
  box-shadow: 0 8px 35px rgb(0 0 0 / 7%);
  border-radius: $border-radius-lg;
  overflow: hidden;

  h2 {
    font-size: $font-size-h2 - 4;
    :global(.anticon) {
      margin-right: $padding-xs;
    }
  }

  :global(.ant-typography-secondary) {
    font-weight: 300;
  }

  &:global(.interactive) {
    cursor: pointer;

    &:hover, &:global(.active) {
      outline: $border-width-md solid var(--color-primary);
    }
  }

}

@media only screen and (max-width: $screen-xs-max) {
  .card {
    padding: 20px 25px;

    &.flat_on_xs {
      padding: 15px 0 30px;
      box-shadow: none;
      border-radius: 0;
    }
  }

}

