@import "../../../../../vars";

.group_wrapper {
  display: flex;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
  background: rgba(0, 0, 0, 0.075); // visual imitation transparent because of shadow
  border-radius: $border-radius-md;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  z-index: 100;
  float: left;

  &.horizontal {
    flex-direction: row;
    background: rgba(0, 0, 0, 0.03);
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: var(--color-white);
  color: var(--color-gray-9);

  &:hover {
    background-color: var(--color-gray-4);
  }

  &.active {
    background-color: var(--color-primary);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-primary-lighten);
    }
  }

  &.control.active {
    background-color: $color-gray-blue;
    color: var(--color-gray-9);
  }

  &.disabled {
    background-color: $color-gray-blue;
    color: var(--color-gray-7);
  }


  // colors
  &.bg-message {
    background-color: #70BAFF;
    color: var(--color-white);
  }

  &.bg-event {
    background-color: #8A80FD;
    color: var(--color-white);
  }

  &.bg-condition {
    background-color: #F8AA34;
    color: var(--color-white);
  }

  &.bg-action {
    background-color: #81C939;
    color: var(--color-white);
  }

  &.bg-timer {
    background-color: #B071FF;
    color: var(--color-white);
  }

  &:focus {
    outline: none;
  }

  & svg {
    width: 20px;
    height: 20px;
  }

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }
}

.group_wrapper + .group_wrapper {
  margin-left: 10px;
}

.button + .button {
  margin-top: 2px;
}

.horizontal .button + .button {
  margin-top: 0;
  margin-left: 2px;
}
