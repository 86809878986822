@import "../../../vars";

.app_wrapper {
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.app_page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: $padding-xl;
}

@media (max-width: $screen-xs-max) {
    .app_page{
        margin-top: 75px;
    }
}
