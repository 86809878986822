@import '../../../../../../mixins';

.buttons_conditions {
  @include flex_row(space-between);

  .add_cond_btn {
    font-size: $font-size-md;
  }
}

.button_condition {
  @include flex_row(center);
  flex-grow: 1;
  color: var(--color_ff) !important;
  background-color: var(--color-primary) !important;
  border: 0;
  outline: none;
  border-radius: 6px;
}

.wrapper_conditions {
  @include flex_row(space-between);

  div {
    margin-right: 15px;
  }

  div:last-child {
    margin-right: 0;
  }
}

.conditions_block {
  //border-bottom: 1px solid var(--color-gray-5);
  padding: 5px 0;
  margin-bottom: 5px;
}

.divider_switch {
  padding-bottom: $padding-sm;

  .divider_label {
    font-size: $font-size-md;
  }

  .divider_radio {
    margin-top: 2px;
  }
}


//[data-checked='true'] {
//    background-color: #0080002b;
//}
//
//[data-checked='false'] {
//    background-color: #ff86863b;
//}

$height: 24px;

.wrapper_head_panel {
  @include flex_row(space-between);
  height: $height;
  margin-bottom: $padding-sm;
  padding-top: 1px;

  .action_title {
    @include ellipsis();
    flex-grow: 1;
    line-height: 28px;
    height: $height;
  }

  .button_fix {
    margin-top: 2.5px;
  }

  .action_mode {
    @include flex_row(flex-start);
    height: $height;
    margin-right: 25px;

    .action_mode_title {
      padding-top: 5px;
    }
  }

  .action_close {
    @include flex_row(center);
    height: $height;
    width: $height;
    cursor: pointer;
    margin-top: 4px;

    & > span {
      svg {
        width: 15px;
        height: 15px;
      }
    }

    &:hover {
      color: var(--color-danger);
    }
  }
}