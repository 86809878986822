@import "../../vars";

.param {
  color: var(--color-gray-7);
  width: 110px;
}

:global(.ant-table-tbody)  > tr > td.actions {
  padding-top: 1px;
}

@media only screen and (max-width: $screen-xs-max) {

}

