@import '../../vars';

.banner_wrapper {
  background: $brand-gradient left;

  .banner {
    width: 100%;
    height: 200px;
    background-color: $transparent;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--color-white);
  }
}

@media only screen and (max-width: $screen-sm-max) {
  .banner_wrapper .banner {
    height: 90px;
  }
}



