@import '../../../../../../mixins';
@import '../../../../../../vars';
.wrapper{
    display: flex;
    align-items: center;
    width: 100%;

    .input {
        margin-bottom: $padding-pm;
        & > span {
            border-radius: 8px !important;
            border: 2px solid var(--color_dc) !important;
            span:first-child {
                width: 100.2%;
                border-radius: 8px !important;
                border-width: 0 !important;
            }
            span:last-child {
                button { display: none; }
            }
        }
    }

    .icon {
        font-size: $icon-size-lg - 2;
        width: $icon-size-lg - 2;
        height: $icon-size-lg - 2;
        color: var(--color-gray-7);
    }
}

