@import "../../vars";

$bar_height: 27px;
$padding_side: 15px;

.progress {
  height: $bar_height;
  position: relative;
  width: 100%;
  background: var(--color-gray-4);
  border-radius: $border-radius-lg;
  overflow: hidden;
  &.lg {
    height: $bar_height + 3;
    .label {
      font-size: $font-size-h3;
    }
    .text {
      line-height: $bar_height + 3;
    }
  }
}

.text{
  line-height: $bar_height;
}

.details{
  right: $padding_side;
  position: absolute;
}

.label {
  color: var(--color-gray-8);
  position: absolute;
  left: $padding_side;
  font-weight: bold;
}

.inner_text{
  color: var(--color-white);
  position: static;
  padding-left: $padding_side;
  padding-right: $padding_side;
}

.inner{
  position: absolute;
  overflow: hidden;
}

.load {
  border-radius: $border-radius-lg;
  background-color: var(--color-primary);
  overflow: hidden;
  height: 100%;
  position: absolute;
  &.danger {
    background-color: $color-danger;
  }
}

