@import "../../../../../../vars";

.wrapper_header_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-left: 11px;
  flex-grow: 1;
  width: calc(100% - 196px);
}

.wrapper_other {
  display: flex;
  align-items: center;
}

.wrapper_header {
  max-width: 100%;
}

// ==== panel header ====

.block_header {
  margin-top: 5px;
  flex-grow: 1;

  & .header {
    font-size: $font-size;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .sub_header {
    white-space: nowrap;
    font-size: $font-size-xs - 2;
    color: var(--color-gray-7);
    margin-top: -4px;
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .wrapper_header_action {
    width: auto;
  }

  // blocks and hiding
  .block_header {
    & .header {
      white-space: normal;
      margin-right: 10px;
      display: inline-block;
      max-height: 48px;
    }

    & .sub_header {
      display: none;
    }
  }
}


