@import '../../../../../../mixins';
@import '../../../../../../vars';

.button_row_wrapper {
  padding: 0 15px 200px;
}

.button_row_wrapper_block {
  padding: 0 0 40px;
}

.button_row {
  width: 100%;
  height: 50px;
  border-radius: $border-radius-lg;
  margin-top: $padding-ps;
  color: var(--color-gray-7);
  border: none;
  //border-width: 2px;
  //border-style: dotted;
  //border-color: var(--color-gray-5);
  box-shadow: none;

  &:focus, &:active {
    color: var(--color-gray-8);
  }

  &:hover {
    //border-color: var(--color-gray-9);
    //border-color: transparent;
    color: var(--color-gray-8);
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.19);
  }
}

.wrapper_button_plus {
  width: $drawer-width;
  max-width: 100%;
  position: fixed;
  padding: 0 15px;
  bottom: 33px;
  z-index: 10;

  .wrapper_buttons {
    @include flex_row(space-between);
    width: 100%;

    .button_plus {
      @include flex_row(center);
      box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
    }

    .buttons_action_add {
      display: flex;
      border-radius: $border-radius-lg;
      overflow: hidden;
      background-color: #F3F3F3;
      box-shadow: 0 8px 14px rgba(0, 0, 0, 0.15);
      z-index: 1;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        margin-right: 2px;
        background-color: var(--color-white);
        cursor: pointer;

        &:hover {
          background-color: var(--color-gray-3);
        }
      }

      .button:last-child {
        margin-right: 0;
      }

      .button_icon {
        margin-top: 5px;
        font-size: 22px;
        color: var(--color-gray-9);
      }

      div {
        margin-right: 2px;
      }
    }
  }
}

