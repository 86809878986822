@import "../../vars";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 15px;

  .form_container {
    position: relative;
    padding: 0;
    width: 100%;
    background-color: transparent;
  }

  .wrapper_form {
    position: relative;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: 510px;
    border-radius: 15px;
    background-color: var(--color-white, #fff);

    .input_field {
      height: 44px;
      font-size: 18px;
      border: 2px solid #DCDFE5;
      border-radius: 8px;
      width: 100%;

      & input {
        font-size: 18px;
      }
    }

    .form_head {
      position: relative;
      font-weight: 500;
      font-size: 24px;
      color: #000;
      margin-bottom: 30px;
    }

    .reg_social_linkblock {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .reg_social {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        width: 100%;
        height: 44px;
        border: 2px solid #DCDFE5;
        border-radius: 8px;
        padding: 0 15px;
        margin-right: 12px;

        .reg_social_text {
          font-size: 18px;
          color: #111;
          margin-left: 10px;
        }
      }
    }

    .hor_line {
      width: 100%;
      height: 0;
      border-top: 2px dashed #DCDFE5;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0;

      .hor_line_title {
        margin-top: -2px;
        background-color: var(--color-white, #fff);
        z-index: 10;
        padding: 0 5px;
      }
    }
  }

  .auth_form {
    position: relative;
    width: 100%;
  }

  .wrapper_buttons {
    display: flex;
    justify-content: center;
  }

  .form_item > div {
    // height: 44px !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .form_item_fields {
    width: 100%;
    margin-bottom: 15px;
  }

  .form_item_button {
    margin-top: 30px;
  }

  .form_check_item > div {
    width: 100% !important;
    max-width: 100% !important;
  }

  .form_button_submit {
    width: 100%;
    height: 44px;
    color: #909399;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    outline: none;

    &[disabled] {
      background-color: #DCDFE5 !important;
    }

    &[disabled]:hover {
      color: #909399;
      background-color: #DCDFE5 !important;
    }
  }

  .form_logo_multy {
    display: flex;
    justify-content: center;
    height: 44px;
    margin-bottom: 60px;

    img {
      height: auto;
      max-height: 100%;
    }
  }

  .have_account {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-top: 60px;
  }

  .have_account_answer {
    color: #111111;
    margin-right: 5px;
  }

  .have_account_action {
    color: var(--color-primary);
    cursor: pointer;

    a {
      color: var(--color-primary-lighten);
    }
  }

  .password_length {
    display: none;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    margin-top: -11px;
    margin-bottom: 10px;
    color: red;
  }

  .wrapper_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }

  .wrapper_icon_vk {
    background-color: #1976D2
  }

  .wrapper_icon_tg {
    background-color: #039BE5
  }

  .faIcon {
    width: 16px;
    height: 16px;
    color: #fff;
  }

  .faTg {
    margin-left: -2px;
  }

  .forgot_go_back {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 2px solid #DCDFE5;
    border-radius: 6px;
    height: 40px;
    margin-bottom: 30px;
    padding: 0 10px;
    cursor: pointer;

    &:focus {
      background: #FFFFFF;
      border: 2px solid #DCDFE5;
      outline: none;
    }
  }
}

.container_auth {
  .form_button_submit {
    color: #fff;
  }
}

.container_password {
  .form_button_submit {
    color: #fff;
  }

  .arrow_left {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: #909399;
  }

}

.container_reg {
  .form_check_box {
    span {
      font-size: 14px;
      color: var(--color-gray-8);
    }
  }
}

@media (max-height: 570px) {
  .container {
    align-items: flex-start;
    position: relative;
    padding: 15px 15px;
  }
}

@media (max-width: 340px) {
  .container {
    .reg_social {
      padding: 0 5px !important;
    }
  }

}

@media (max-width: 300px) {
  .container {
    .reg_social {
      margin-right: 5px !important;
    }
  }
}