@import "../../vars";
@import "../../mixins";

$color-stroke-selected: #ffdf6b;

.flow {
  background-color: var(--color-gray-2);
  background-image: url("../../../public/image/grid.png");
  width: 100vw;
  height: 100vh;

  :global .react-flow__attribution {
    display: none;
  }
  // :global .react-flow__viewport { background: #faebd782; }

  :global .icon-message {
    color: #70BAFF;
  }
  :global .icon-event {
    color: #5E80FF;
  }
  :global .icon-condition {
    color: #DC781C;
  }
  :global .icon-action {
    color: $color-success;
  }
  :global .icon-timer {
    color: #875EFF;
  }

}

