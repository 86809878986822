@import "vars";
@import "mixins";

:root {
  // deprecated
  --color_d9: #D9D9D9;
  --color_dc: #DCDFE5;
  --color_f1: #F1F1F1;
  --color_ae: #AEBBFF;
  --color_ea: #EAEAEA;
  --color_f5: #F5F5F5;
  --color_f9: #F9F9F9;
  --color_ff: #FFFFFF;

  // flow colors
  --color_back_cond: #FFB978;
  --color_back_event: #AEBBFF;
  --color_back_effect: #B9E895;
  --color_back_timer: #C2ACFF;
  --color_back_message: #AECFFF;

  // common vars
  --color-primary: #5D80FF;
  --color-primary-lighten: #7794ff;
  --color-primary-hover: #C8D4FF;
  --color-primary-light: #E6EEFF;
  --color-primary-bg: #F1F7FF;

  --color-success: #67C23A;
  --color-warning: #EFB662;
  --color-gold: #FFAA2E;
  --color-note: #FFE55C;
  --color-danger: #F56C6C;
  --color-error: #F56C6C;
  --color-info: #5284FC;
  --color-landing: #F6F7F9;
  --color-purple: #8D5ED8;

  --color-black: #000000;
  --color-gray-9: #333333;
  --color-gray-8: #606266;
  --color-gray-7: #939393;
  --color-gray-6: #C8C8C8;
  --color-gray-5: #DFDFDF;
  --color-gray-4: #F1F1F1;
  --color-gray-3: #F7F7F7;
  --color-gray-2: #FAFAFA;
  --color-white: #FFFFFF;
  --transparent: transparent;

  --font-size: 14px;
  --font-size-h1: ceil(var(--font-size)*2.428);
  --font-size-h2: ceil(var(--font-size)*1.71);
  --font-size-h3: ceil(var(--font-size)*1.285);
  --font-size-h4: ceil(var(--font-size)*1.14);
  --font-size-h5: ceil(var(--font-size)*1);
  --border-radius-md: 6px;
  --border-radius-lg: 8px;
  --border-color-hover: #C8D4FF;
  --border-color-focus: var(--color-primary);
  --timer-input-height-small: 26px;
  --timer-input-height-default: 34px;
  --timer-input-height-large: 42px;
}

// =========== Ant DESIGN Rewrite Styles - Start ============

.ant-dropdown-menu-item-icon {
  font-size: $font-size-md;
}

.ant-dropdown-menu-title-content a.allow-extra-icon:after {
  width: calc(100% - 45px);
}

// Ant: Buttons
.ant-btn.ant-btn-default.btn-default-gray {
  background-color: var(--color-gray-4);
  border-color: transparent;

  &:hover, &:focus, .ant-dropdown-open {
    color: var(--color-gray-9);
    background-color: var(--color-gray-5);
    border-color: transparent;
  }
}

.ant-btn-text {
  color: var(--color-gray-8);

  &.inverted {
    color: var(--color-gray-7);
  }
}

.ant-btn-text:hover, .ant-btn-text:focus {
  color: var(--color-gray-7);
  background: $transparent;

  &.inverted:not(.active) {
    color: var(--color-gray-8);
  }
}

.ant-btn-text.active, .ant-btn-text:active {
  color: var(--color-primary);
  background: $transparent;
}

.ant-btn-text.active:hover, .ant-btn-text.active:active {
  color: var(--color-primary-lighten);
}

.ant-btn-dangerous.ant-btn-text:hover, .ant-btn-dangerous.ant-btn-text:focus {
  background: $transparent;
}

.ant-btn-dangerous.ant-btn-text:focus {
  color: $color-danger;
}

.btn-xlg {
  font-size: $font-size-lg;
}

.anticon .svg-inline--fa {
  vertical-align: 0;
}

.ant-btn {
  > .anticon {
    vertical-align: middle;

    &.fa-wrap {
      vertical-align: revert;
    }
  }

  &.fix-height {
    height: auto;
    min-height: $icon-size-lg*(1+1/3);
  }

  &.btn-borderless {
    border: none;
  }

  &.btn-colored {
    border: none;
    color: var(--color-white);

    &:hover, &:active {
      background: var(--color-white);
    }
  }

  &.ant-btn-primary {
    text-shadow: none;
    box-shadow: none;

    &.btn-bordered:not([disabled]) {
      background-color: transparent;
      border-width: 2px;
      border-color: var(--color-primary);
      color: var(--color-gray-9);

      &.ant-btn-dangerous {
        border-color: $color-danger;
      }

      &:hover, &:focus {
        color: var(--color-white);
        background-color: var(--color-primary);

        &.ant-btn-dangerous {
          background-color: $color-danger;
        }
      }
    }
  }

  .ant-btn-default.btn-bordered:not([disabled]) {
    border-width: 2px;
    border-color: var(--color-gray-8);

    &:hover, &:focus {
      background: none;
      border-color: var(--color-primary);
    }
  }

  &.btn-success {
    color: var(--color-white);
    background: $color-success;
    border-color: $color-success;

    &.ant-btn-background-ghost, &.ant-btn-text {
      color: $color-success;
    }

    &:hover, &:active {
      color: var(--color-white);
      background: lighten($color-success, $lighten-1);
      border-color: lighten($color-success, $lighten-1);

      &.ant-btn-background-ghost, &.ant-btn-text {
        color: $color-success;
      }
    }
  }

  &.btn-info {
    color: var(--color-white);
    background: $color-info;
    border-color: $color-info;

    &.ant-btn-background-ghost, &.ant-btn-text {
      color: $color-info;
    }

    &:hover, &:active {
      color: var(--color-white);
      background: lighten($color-info, $lighten-1);
      border-color: lighten($color-info, $lighten-1);

      &.ant-btn-background-ghost, &.ant-btn-text {
        color: $color-info;
      }
    }
  }

  &.btn-warning {
    background: $color-warning;
    border-color: $color-warning;

    &.ant-btn-background-ghost, &.ant-btn-text {
      color: $color-warning;
    }

    &:hover, &:active {
      background: lighten($color-warning, $lighten-1);
      border-color: lighten($color-warning, $lighten-1);

      &.ant-btn-background-ghost, &.ant-btn-text {
        color: $color-warning;
      }
    }
  }

  &.btn-danger {
    color: var(--color-white);
    background: $color-danger;

    &.ant-btn-background-ghost, &.ant-btn-text {
      color: $color-danger;
    }

    &:hover, &:active {
      color: var(--color-white);
      background: lighten($color-danger, $lighten-1);

      &.ant-btn-background-ghost, &.ant-btn-text {
        color: $color-danger;
      }
    }
  }

  &.btn-inverted {
    color: var(--color-gray-9);
    background: var(--color-white);
    border-color: var(--color-white);

    &.ant-btn-background-ghost, &.ant-btn-text {
      color: var(--color-white);
    }

    &:hover, &:active {
      color: var(--color-gray-9);
      background: var(--color-gray-2);
      border-color: var(--color-white);

      &.ant-btn-background-ghost, &.ant-btn-text {
        color: var(--color-white);
      }
    }
  }

  // for chat-bot keyboards
  &.btn-default {
    color: var(--color-gray-9);
    background: $color-light-button;

    &:hover, &:active {
      background: darken($color-light-button, 2%);
    }
  }

  &.btn-gray {
    background: var(--color-gray-6);

    &:hover, &:active {
      background: var(--color-gray-7);
    }
  }

  &.ant-btn-icon-only.font-size-sm > * {
    font-size: $font-size-sm;
  }

  &.btn-gray-light {
    border: none;
    color: var(--color-gray-9);
    background: var(--color-gray-4);

    height: $btn-size-md;

    &.ant-btn-icon-only {
      width: $btn-size-md;
    }

    &:hover, &:active {
      color: var(--color-gray-9);
      background: var(--color-gray-5);
    }

    &:not(:last-child) {
      margin-right: $padding-xs;
    }
  }

  &.btn-bordered {
    color: var(--color-gray-9);

    &:hover, &:active {
      color: var(--color-gray-9);
    }
  }

  &.ant-btn-text {
    border-color: transparent;

    &:hover, &:active {
      border-color: transparent;
    }
  }

  &.ant-btn-text, &.btn-bordered, &.ant-btn-background-ghost {
    background-color: transparent;

    &:hover, &:active {
      background-color: transparent;
    }
  }
}

.height-auto .ant-btn {
  height: auto;
}

.button-flying {
  position: fixed;
  bottom: $padding-ps;
  z-index: 100;

  .ant-btn-default {
    border-color: var(--color-primary);
  }
}

.btn-bordered {
  .ant-btn-default[disabled] {
    border-width: 2px;
    border-color: var(--color-gray-5);
  }
}

.ant-btn {
  border-width: $border-width-md;
  min-height: $btn-size-md;
  //height: $btn-size-md; // broke flow save bnt height
  //height: auto; // broke table line height
  //min-height: 32px;
}

.ant-btn::before {
  top: -$border-width-md;
  right: -$border-width-md;
  bottom: -$border-width-md;
  left: -$border-width-md;
}

.ant-btn-hide-loading-icon {
  .ant-btn-loading-icon {
    display: none;
  }
}

.ant-btn-sm {
  min-height: $btn-size-sm;
}

.ant-btn-group .ant-btn + .ant-btn, .ant-btn + .ant-btn-group, .ant-btn-group span + .ant-btn, .ant-btn-group .ant-btn + span, .ant-btn-group > span + span, .ant-btn-group + .ant-btn, .ant-btn-group + .ant-btn-group {
  margin-left: -$border-width-md;
}

.inherit-icon-size {
  width: auto;
  height: auto;
  line-height: 1em;
  min-height: 1em;
}

.graph-search-wrapper {
  .ant-input-search .ant-input-search-button,
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus,
  .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-color: transparent;
  }
}

.graph-panel-platform-dropdown .anticon.anticon-down {
  padding-top: 4px;
}

// Ant: Modal
.modal-clean {
  .ant-modal-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-body {
    padding-top: $padding-xp;
  }

  .ant-modal-footer {
    border-top: none;
  }

  .form-item-wrapper {
    margin-bottom: $padding-ps;
  }
}

.compact-modal .ant-modal-close-x {
  height: 40px;
  line-height: 40px;
}

.scrollable .ant-modal-body {
  max-height: 600px;
  overflow-y: scroll;
}

.ant-modal-footer .float-left:first-child:not(.ant-btn-icon-only) {
  padding-left: 8px;
  padding-right: 8px;
}


// Ant: PageHeader
.ant-layout-header {
  height: auto;
}

.ant-page-header.app-page-header {
  display: block;
}

.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: nowrap;
}

.ant-page-header-heading-title, .ant-typography.font-medium {
  font-weight: 500;
}

.ant-page-header-heading-extra {
  a.ant-btn-icon-only:not(.default) {
    width: auto;
    margin-right: 5px;
    padding-left: 8px;
    padding-right: 8px;

    > .anticon {
      padding-top: 5px;
    }
  }

}

// Ant: Notice ---
.ant-message-notice-content {
  border-radius: 8px;
}

// Override Ant Design Alert to look like Bootstrap Alert
.ant-alert-success {
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.ant-alert-info {
  background-color: #E9EEFF;
  border-color: #ADB8DF;
}

.ant-alert-warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.ant-alert-error {
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.ant-alert-success .ant-alert-message {
  color: #155724;
}

.ant-alert-info .ant-alert-message {
  color: #5768A4;
}

.ant-alert-warning .ant-alert-message {
  color: #856404;
}

.ant-alert-error .ant-alert-message {
  color: #8a2730;
}

// Ant CheckBox: назначить чекбоксы соответствующий класс => <Checkbox className='checkbox-default'/>
.disable-check-animation {
  .ant-checkbox-inner, .ant-checkbox-checked .ant-checkbox-inner::after, .ant-checkbox-checked::after,
  .ant-switch, .ant-switch-handle, .ant-switch-inner {
    transition: none;
  }

  .ant-checkbox-checked::after,
  [ant-click-animating-without-extra-node="true"]::after, .ant-click-animating-node {
    animation: none;
  }
}

.checkbox-row {
  padding: 4px 2px 0;
  border-radius: $border-radius-sm;

  &:hover {
    background: var(--color-gray-3);
  }

  .ant-form-item-label > label{
    font-size: $font-size-sm;
    &::after {
      display: none;
    }
  }

  .float-right {
    line-height: 30px;
  }
}

.checkbox-default {
  @include check_box_type(default)
}

.checkbox-large {
  @include check_box_type(large)
}

.fix-checkbox-align {
  @include flex_row(flex-start, flex-start);
  flex-grow: 1;
  margin-bottom: 3px;

  & > span:first-child {
    margin-top: 1px;
  }

  //.ant-checkbox-inner {
  //  margin-top: -1px;
  //}

  .ant-checkbox-inner::after {
    top: 44%;
  }
}

.checkbox-small {
  > span {
    font-size: $font-size-sm;
  }

  &.fix-checkbox-align {
    & > span:first-child {
      margin-top: -1px;
    }
  }
}

// Ant: Forms

.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    border-radius: $border-radius-sm;
  }
}

.label-platform-icons {
  display: inline-block;
  margin-left: $padding-xp;
  color: #ccc;

  > span {
    margin-right: $padding-xp;
  }
}

.ant-input[readonly] {
  color: var(--color-gray-8);
  background-color: #f5f5f5;
  box-shadow: none;
  cursor: default;
}

.ant-input-number {
  &.align-right {
    margin-right: -22px;

    .ant-input-number-input {
      text-align: right;
      padding-right: $padding-sm + 2;
    }
  }

  &.align-center {
    .ant-input-number-input {
      text-align: center;
      padding-right: $padding-sm + 2;
    }
  }

  &.always-show-arrows {
    .ant-input-number-handler-wrap {
      opacity: 1;
    }
  }

  &.clear-disabled.ant-input-number-disabled {
    background: none;
  }
}

.ant-slider.primary {
  .ant-slider-track {
    background-color: var(--color-primary);
  }

  .ant-slider-handle {
    border: solid 2px var(--color-primary);
  }
}


textarea.ant-input, .ant-input-textarea-show-count > .ant-input {
  height: 35px; // set autosize for all textarea fields
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 7px;
  padding-bottom: 0.2rem;
  color: $color-danger;
  font-size: $font-size-md;
  line-height: 1;
  content: '*';
}

.effects-list {
  .form-item-wrapper {
    margin-bottom: $padding-ps;

    &.margin-bottom-xs {
      margin-bottom: $padding-xs;
    }
  }

  .form-section-toggle {
    margin-top: $padding-xp;
    margin-bottom: $padding-xs;
  }

  .cond-divider {
    &.ant-divider-horizontal.ant-divider-with-text {
      color: rgba(0, 0, 0, 0.3);
      font-weight: 500;
      font-size: 14px;
      border-top-color: rgba(0, 0, 0, 0.18);
      border-width: 2px 0 0;
    }

    &.ant-divider-horizontal.ant-divider-with-text::before, &.ant-divider-horizontal.ant-divider-with-text::after {
      border-top-width: 2px;
    }
  }

  .ant-input-textarea-show-count::after {
    float: left;
    position: absolute;
    left: 3px;
    bottom: -10px;
  }

  .fix-counter {
    .ant-input-textarea-show-count::after {
      position: relative;
      bottom: -7px;
      margin-bottom: 0;
    }
  }

  .ant-form-item:not(.allow-overflow) .ant-form-item-label label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: inline-block;
    margin-bottom: -$padding-xs;
  }
}

.form-section-wrapper {
  margin-bottom: $padding-pm;
}

.form-item-wrapper {
  margin-bottom: $padding-pm;

  &.margin-none, &.field-type-hidden {
    margin-bottom: 0;
  }

  .ant-form-item {
    width: 100%;
    margin: 0;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-form-item-label {
    width: 100%;

    label {
      // font-size: $font-size-md;
      font-size: $font-size-sm;
    }
  }

  .ant-input-sm {
    font-size: $font-size-sm;
  }

  .ant-field-sm {
    .ant-form-item-label {
      padding-bottom: $padding-xs / 2;

      label {
        font-size: $font-size-xs;
      }
    }

    .ant-select-selection-item {
      font-size: $font-size-xs;
    }
  }

  .form-item-desc {
    margin-top: $padding-xs;
    font-size: $font-size-xs;
    color: var(--color-gray-8);
    white-space: break-spaces;
  }

  .switcher-field + .form-item-desc {
    margin-top: $padding-xs / 2;
  }

  &.color-danger {
    .form-item-desc {
      color: $color-danger;
    }
  }

  &.field-type-description .form-item-desc {
    margin-top: 0;
  }

  .image-uploader {
    .label {
      max-height: 50px;
      overflow: hidden;
      padding: 0 2px;
    }
  }
}

.field-type-warning {
  .ant-form-item-label {
    padding: 0;
  }

  label {
    color: $color-danger;
  }

  margin-top: -25px;
}

// text editor
.ant-form {
  .tox {
    &.tox-tinymce {
      border-radius: $border-radius-md;
    }

    &.tox-tinymce.focus {
      border-color: var(--color-primary);
    }

    .tox-toolbar__group {
      padding: 0 7px 0 8px;
    }

    .tox-tbtn svg {
      fill: var(--color-gray-9);
    }

    .tox-tbtn--disabled svg, .tox-tbtn--disabled:hover svg, .tox-tbtn:disabled svg, .tox-tbtn:disabled:hover svg {
      fill: var(--color-gray-7);
    }

    &:not(.tox-tinymce-inline) .tox-editor-header {
      border-bottom: 2px solid #ececec;
      box-shadow: none;
    }
  }

  .code-editor {
    &.ant-input {
      border: none;
      // border-radius: 0;
    }

    > pre, > textarea {
      display: block !important;
      padding: 5px 11px 7px !important;
      border: $border-width-md solid var(--color-gray-5) !important;
      border-radius: $border-radius-md !important;

      &:hover {
        border-color: var(--color-primary-hover) !important;
      }

      &:focus {
        border-color: var(--color-primary) !important;
      }
    }

    ::selection {
      border-color: var(--color-primary);
      color: var(--color-white) !important;
      -webkit-text-fill-color: var(--color-white) !important;
    }

    ::placeholder {
      color: var(--color-gray-7) !important;
    }
  }
}

.allow-overflow {
  overflow: visible;

  .ant-form-item-label {
    overflow: visible;
  }
}

.ant-switch {
  margin-right: $padding-xp;

  &.switch-danger.ant-switch-checked {
    background: $color-danger;
  }

  &.ant-switch-checked.ant-switch-disabled {
    background-color: var(--color-gray-7);
  }
}

.ant-radio-inner {
  width: 16px;
  height: 16px;
  border-color: var(--color-gray-6);
  border-width: 2px;
}

.ant-radio-checked .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--color-gray-6);
}

.ant-radio-wrapper:last-child {
  margin-right: 0;
}

.ant-radio-group-solid {
  .ant-radio-button-wrapper {
    &:hover {
      background-color: var(--color-gray-3);
      color: inherit;
    }
  }

  &.buttons-colors {
    margin-bottom: -$padding-xp;

    .ant-radio-button-wrapper {
      height: auto;
      border-width: 2px;
      border-radius: $border-radius-md;
      margin-right: $padding-xp;
      margin-bottom: $padding-xp;

      &:before, &:after {
        display: none;
      }

      &.btn-primary {
        border-color: var(--color-primary-hover);
      }

      &.btn-success {
        border-color: lighten($color-success, 20%);
      }

      &.btn-danger {
        border-color: lighten($color-danger, 15%);
      }
    }

    .ant-radio-button-wrapper-checked.ant-radio-button-wrapper {
      &.btn-primary {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
      }

      &:before {
        display: none;
      }
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    &:focus-within {
      box-shadow: none;
    }

    &.btn-success {
      background-color: $color-success;
      border-color: $color-success;

      &:before {
        background-color: $color-success;
      }
    }

    &.btn-danger {
      background-color: $color-danger;
      border-color: $color-danger;

      &:before {
        background-color: $color-danger;
      }
    }

    // for chat-bot keyboards
    &.btn-default {
      background-color: lighten($color-gray-blue, 5%);
      border-color: lighten($color-gray-blue, 5%);
      color: var(--color-gray-9);

      &:before {
        background-color: lighten($color-gray-blue, 5%);
      }
    }
  }
}

.ant-tag {
  font-size: $font-size-md;
  font-weight: normal;

  &.tag-editable {
    padding: 0;

    .editable {
      padding: 0 6px 0 $padding-xp;
      margin: 0;
    }

    .ant-tag-close-icon {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      font-size: 10px;
      line-height: inherit;
      text-align: center;
      text-rendering: optimizelegibility;
      text-transform: none;
      vertical-align: -.125em;
      margin: 0;
      padding: 0 4px 0 2px;
    }
  }
}

//.ant-input, .ant-select, .ant-picker-input > input, .ant-input-number, .ant-select-item, .ant-cascader-menu-item-content{
//  font-size: $font-size-md;
//}

// ant input
.ant-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker,
.ant-input-number,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-color: var(--color-gray-5);
  border-width: $border-width-md;
}

// ant input: hover
.ant-input:hover, .ant-input-hovered,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-picker:hover,
.ant-input-number:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--color-primary-hover);
  border-right-width: $border-width-md;
  box-shadow: none;
}

// ant input: focus
.ant-input:focus, .ant-input-focused,
.ant-select-focused.ant-select:not(.ant-select-disabled):hover .ant-select-selector, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-focused, .ant-picker-focused:hover,
.ant-input-number.ant-input-number-focused,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
  border-color: var(--color-primary);
  border-right-width: $border-width-md;
  box-shadow: none;
}

.ant-input-group.ant-input-group-compact > :last-child, .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector, .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input, .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-width: $border-width-md !important;
  height: auto;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  opacity: 1;
}

.ant-upload {
  &.ant-upload-select-picture-card, &.ant-upload-drag {
    border: 2px dashed var(--color-gray-6);
    transition: none;

    .ant-upload {
      color: var(--color-gray-7);
    }
  }

  &.ant-upload-select-picture-card:hover, &.ant-upload-drag:not(.ant-upload-disabled):hover {
    border: 2px dashed var(--color-gray-8);

    .ant-upload {
      color: var(--color-gray-9);
    }
  }
}

.ant-upload-list-item.ant-upload-list-item-list-type-text {
  position: relative;
  height: auto;

  .ant-upload-list-item-info {
    padding: 10px 15px;
    border-radius: 6px;
    border: 2px solid rgb(221, 221, 221);
    //box-shadow: $card-shadow;

    .anticon {
      font-size: $font-size-xl;
    }
  }

  a.ant-upload-list-item-name {
    color: var(--color-gray-9);

    &:hover {
      text-decoration: underline;
    }
  }
}

.ant-upload-list-item-actions > a {
  vertical-align: middle;
  padding-top: 2px;
}

.ant-upload-hint {
  font-size: $font-size-sm;
}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
  box-shadow: $card-shadow;
  overflow: hidden;
  padding: 0;
  border-radius: $border-radius-lp;

  //background: var(--color-gray-9) !important;
  //.ant-upload-list-item-info {
  //  background: var(--color-gray-9) !important;
  //}
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  color: var(--color-white);
  object-fit: cover;
}

.fit-contain {
  object-fit: contain;

  .ant-upload-list-item-thumbnail, .ant-upload-list-item-thumbnail img {
    object-fit: contain;
  }
}

//.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
//  line-height: 28px;
//}

.upload-list-item-list-type-picture-card {
  .ant-upload-list-item-actions > a {
    line-height: 35px;
    vertical-align: top;
  }

  .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    height: 34px;
  }
}

.ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
}

.form-item-wrapper.full-width .image-uploader:not(.upload-replace) {
  .ant-upload-select-picture-card {
    width: 100%;
    height: 50px;

    span > div {
      > svg {
        float: left;
        margin-right: $padding-xs;
      }
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px;
  padding: 1px 11px 0;
}

// Ant: Tree

.book-tree {
  .ant-tree .ant-tree-node-content-wrapper {
    .ant-tree-title {
      display: inline-block;
      width: 100%;
    }

    .label-span {
      padding: $padding-xp / 2 $padding-xp;
    }
  }

  .ant-tree-switcher {
    padding-top: $padding-xp / 2;
    color: var(--color-gray-8);
  }

  .ant-tree-treenode {
    border-radius: $border-radius-sm;
  }

  .ant-tree-treenode.ant-tree-treenode-selected {
    background-color: var(--color-primary-light);
  }

  .ant-tree-treenode:hover {
    background-color: var(--color-gray-3);
  }

  .ant-tree .ant-tree-node-content-wrapper:hover, .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: transparent;
  }

  .ant-tree-switcher-noop::before {
    content: '•';
    color: var(--color-gray-7);
  }

  .ant-tree.loading {
    .ant-tree-switcher-noop::before {
      // add some spinner by symbol with animation:
      //content: '◜';
      content: '▪';
      display: inline-block;
      animation: spin 1s linear infinite;

      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}


// Ant: Table

.ant-table-tbody > tr > td {
  &.border-none {
    border-bottom: none;
  }

  &.padding-left-none {
    padding-left: 0;
  }

  &.padding-bottom-none {
    padding-bottom: 0;
  }

  &.padding-vertical-xs {
    padding-top: $padding-xs;
    padding-bottom: $padding-xs;
  }

  &.padding-none-horizontal {
    padding-left: 0;
    padding-right: 0;
  }
}

.border-last-none .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}

.list-default-table-wrapper {
  @include section_block_card;
  overflow: hidden;
}

.list-default-table {
  &.table-align-top {
    td {
      vertical-align: top;
    }
  }

  .ant-dropdown-menu {
    min-width: 136px;
  }

  .max-width-270 {
    max-width: 270px;
  }

  .ant-table-thead > tr > th {
    background-color: var(--color-white);
    color: var(--color-gray-7);
    font-weight: normal;

    &.align-center {
      text-align: center;
    }
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  // table sorters
  .ant-table-column-has-sorters {
    .ant-table-column-sorter {
      display: none;
    }

    .column-sorter-wrapper {
      .anticon {
        margin-left: $padding-xs;
      }

      .anticon-arrow-up, .anticon-arrow-down {
        display: none;
      }
    }
  }

  .ant-table-column-has-sorters[aria-sort="ascending"] .column-sorter-wrapper {
    .anticon-arrow-up {
      display: inherit;
    }
  }

  .ant-table-column-has-sorters[aria-sort="descending"] .column-sorter-wrapper {
    .anticon-arrow-down {
      display: inherit;
    }
  }

  // table body
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  // table cell images
  $avatar-size: 48px;
  $icon-size: 34px;

  .avatar .image {
    // background-color: var(--color-gray-6);
    background-image: $brand-gradient;
    background-position: center;
    background-size: cover;
    width: $avatar-size;
    height: $avatar-size;
    min-width: $avatar-size;
    min-height: $avatar-size;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;

    &.w-34 {
      width: $icon-size;
      height: $icon-size;
      min-width: $icon-size;
      min-height: $icon-size;
    }

    .avatar-content {
      font-size: $font-size-sm;
      color: transparent;
      background-color: transparent;
      transition: background-color 300ms ease-in, color 300ms ease-in;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        background-color: var(--color-primary);
        color: var(--color-white);
        // opacity: 0.8;
      }

      > .icon {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.icon {
      width: $icon-size;
      height: $icon-size;
      min-width: $icon-size;
      min-height: $icon-size;
      margin: 0;

      .avatar-content {
        font-size: $font-size-xs - 1;
        color: var(--color-white);
        background-color: var(--color-primary);
      }

      &.disabled .avatar-content {
        background-color: $color-danger;
      }
    }
  }

  // table left padding
  > td:first-child, .ant-table-tbody > tr > td:first-child, .ant-table-thead > tr > th:first-child {
    padding-left: 24px;
  }

  .drag-visible {
    padding: 0 !important;
  }

  .drag-handler {
    padding: 32px 0 32px 24px;
  }


  // table title (+ collapse)
  .ant-table-title {
    padding: 0;
    border-bottom: $border-width-base solid var(--color-gray-4);
    user-select: none;

    h3 {
      padding: 16px 16px 16px 24px;
      color: var(--color-gray-7);
      margin: 0;

      &.color-danger {
        color: $color-danger;
      }
    }
  }

  // table checkbox
  table tr td.ant-table-selection-column, table tr th.ant-table-selection-column {
    padding-top: 16px;
    padding-right: 8px;
  }

  .menu-column {
    padding-left: 0;
  }

  // table row title
  .table-row-title {
    //&.title-link{
    //  //transition: color $transition-time $transition-style;
    //  &:hover{
    //    color: var(--color-primary);
    //  }
    //}
  }

  // table tex buttons
  .table-row-buttons {
    display: inline-flex;

    .ant-btn, a.ant-btn {
      font-size: $icon-size-lg;
      color: var(--color-gray-7);

      cursor: pointer;
      padding-left: 2px;
      padding-right: 6px;
      margin-right: 5px;
      line-height: 32px;

      &:hover {
        color: var(--color-gray-8);
      }

      &.ant-btn-icon-only {
        padding: 0;
        margin-right: 0;
      }
    }

    //a.ant-btn {
    //  // padding-top: 7px !important;
    //  &.fix-icon-padding {
    //    padding-top: 4px !important;
    //  }
    //}

    // a {
    //  // margin-top: -1px;
    //  padding-top: 1.5px !important;
    //}

    .ant-btn > .anticon-delete {
      padding-bottom: 1px;
    }

    .ant-btn > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &.title-controls {
      button {
        font-size: $icon-size-md;
        padding-top: 15px;
        height: 100%;

        &:last-child {
          margin-right: 0;
        }

        &.btn-collapse {
          padding-right: 16px;
        }
      }
    }
  }

  // table-pagination
  .ant-table-pagination {
    margin-top: $padding-ps;
    padding: 0 $padding-sm $padding-xs;
  }

  .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
    min-width: $btn-size-md + 1;
    height: $btn-size-md + 1;
  }

  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: var(--color-gray-9);
  }

  .ant-pagination-item, .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-color: var(--color-gray-4);
    border-width: $border-width-md;
    // border-width: 0;
    // box-shadow: 2px 3px 7px $color-shadow-3;
    &:hover {
      color: var(--color-gray-9);
      border-color: var(--color-gray-7);

      a {
        color: var(--color-gray-9);
      }
    }
  }

  //.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  //  border-color: var(--color-gray-5);
  //}
  .ant-pagination-item-active {
    border-color: var(--color-gray-6);

    a {
      color: var(--color-gray-9);
    }
  }

  .ant-pagination-options {
    position: absolute;
    float: right;
    right: $padding-sm;
  }

}

.user-profile {
  header .ant-page-header-heading-title {
    font-weight: 400;
  }
}

.users-list {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding-bottom: $padding-xp;
    padding-top: $padding-xp;
  }

  $avatar-size: 45px;

  .avatar .image {
    width: $avatar-size;
    height: $avatar-size;
    min-width: $avatar-size;
    min-height: $avatar-size;
  }

  .list-default-table table tr td.ant-table-selection-column, .list-default-table table tr th.ant-table-selection-column {
    padding-top: $padding-xp;
  }
}

.list-default-table .ant-table-content {
  // transition: height $transition-time $transition-style;
}

.hide-table .list-default-table .ant-table-content {
  // height: 0 !important;
  display: none;
}

.drag-handler {
  cursor: grab;
  color: var(--color-gray-7);
}

.dragging {
  .drag-handler:hover {
    cursor: grabbing !important; // TODO: not working
  }
}

.table-row-dragging {
  display: flex;
  align-items: center;
  background: var(--color-gray-2);
  box-shadow: $box-shadow-base;

  td {
    padding: 16px;
  }

  .drag-visible {
    visibility: visible;
  }

  .drag-hide {
    display: none;
  }
}


//Collapse
.ant-card-type-inner .ant-card-head {
  padding: 0 20px;
  background: var(--color-gray-4)
}

h3.collapsible-subheader {
  font-weight: 500;
  border-bottom: $border-width-md solid var(--color-gray-4);
  padding-bottom: $padding-xp + 4;
  margin-bottom: $padding-sm;
  cursor: pointer;

  .anticon {
    margin-right: 15px;
  }

  .float-right .anticon {
    color: var(--color-gray-7);
    margin-right: 0;
  }
}

.ant-card-head-title .filter-panel-header {
  span {
    padding: 0 12px;
  }

  svg {
    color: var(--color-gray-6)
  }
}

.ant-card-type-inner {
  // only for filters cards
  &.filters {
    .ant-card-body {
      background: #F9F9F9;
    }
  }

  // only for collapsible cards
  &.collapsible {
    .ant-card-head-title {
      padding: 0;
    }

    .ant-card-body {
      padding: 0;

      .ReactCollapse--content {
        padding: 16px 16px;
      }

      .form-item-wrapper {
        display: flex;

        .ant-form-item-label {
          padding-left: 3px;
        }

        &.field-combined {
          flex-direction: column;

          .form-input-group {
            display: flex;
          }
        }
      }
    }

    .ant-row {
      .ant-form-item:not(:first-child) {
        margin: 0 0 0 5px;
      }
    }

    form > .ant-row > .ant-col:nth-child(2n-1) {
      padding-right: 3px;
    }

    form > .ant-row > .ant-col:nth-child(2n) {
      padding-left: 3px;
    }

    .ant-form-vertical .ant-form-item {
      flex-direction: inherit;
    }

    &.filters {
      .ReactCollapse--content {
        padding-right: 16px;
      }
    }

    .ant-picker {
      width: 100%;
    }

  }
}


.filter-panel-header {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.filters {
  .form-item-wrapper {
    margin-bottom: 10px;
  }
}

.header-filter-buttons-xs {
  width: 100%;

  .search-input-wrapper {
    width: 60%;
  }
}

// Collapse Card
.collapse-row-buttons {
  display: inline-flex;

  button {
    font-size: $icon-size-lg;
    color: var(--color-gray-7);

    cursor: pointer;
    padding-left: 2px;
    padding-right: 6px;
    margin-right: 5px;

    :hover {
      color: var(--color-gray-8);
    }
  }

  &.title-controls {
    button {
      font-size: $icon-size-md;
      padding-top: 18px;
      height: 100%;

      &:last-child {
        margin-right: 0;
      }

      &.btn-collapse {
        padding-right: 40px;
      }
    }
  }
}

// Ant: Progress

.account-progress {
  .ant-progress-text {
    position: absolute;
    top: 7px;
    right: 30px;
  }

  .account-progress-pre-text {
    color: var(--color-white);
    font-weight: bold;
    position: absolute;
    margin-left: 10px;
    margin-top: 4px;
    z-index: 10;
  }

  .ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }
}

// Ant: Tabs
.control-panel-tabs {
  .ant-tabs-tab {
    padding-left: $padding-xp;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    padding-left: $padding-ps;
  }

  .ant-tabs-tab {
    flex-grow: 1;
  }
}

.tabs-buttons {
  .ant-tabs-ink-bar, > .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-tab {
    padding: 0;

    + .ant-tabs-tab {
      margin: 0 0 0 $padding-xs;
    }
  }

  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      padding: 2px 10px;
      margin-bottom: 12px;
      white-space: nowrap;
      outline: none;
      color: var(--color-gray-9);
      font-size: $font-size;
      background: var(--color-gray-3);
      border: $border-width-md solid var(--color-gray-5);
      border-radius: $border-radius-md;
      transition: all 0ms;
    }

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
      color: var(--color-white);
    }
  }
}

// Ant: Carousel
.ant-carousel .white .slick-dots li button {
  background: var(--color-gray-6);
}

.carousel-with-controls {
  .ant-carousel {
    margin: 0 50px;
  }
}

.ant-carousel .slick-dots li.slick-active button {
  background: var(--color-primary);
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  opacity: inherit;
}

.carousel-with-controls-mobile {
  .ant-carousel .slick-dots-bottom {
    bottom: -32px;
  }

  .slick-active {
    width: inherit;
  }

  .ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 16px;
    height: 3px;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s;
  }

  .ant-carousel .slick-dots li button {
    width: 8px;
    height: 8px;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
  }
}

// Ant: Card

// TODO: replace it to component
.store-actions {
  .ant-card-actions {
    padding: 0 20px 0;

    li {
      span {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .ant-btn {
    font-size: $font-size-md;
    height: 100%;
  }

  .demo {
    border: 2px solid transparent;
  }

  .more {
    border: 2px solid var(--color-primary);
  }

  .add {
    border: 2px solid var(--color-primary);
    width: 56px;
    height: 100%;
    padding: 8px 16px;
  }
}


// Ant: Other
//.auth-form .ant-form-item-explain-error {
//  display: none;
//}

// ======== Other Components Styles ==============

// ReactCollapse animation
.ReactCollapse--collapse {
  transition: height $transition-time $transition-style;
}

// SimpleCollapse // TODO: create script like in bootstrap
.collapse {
  transition: max-height $transition-style $transition-time;
  height: 0;

  &.show {
    height: auto;
  }
}

// ======== Common Custom Styles ==============

body {
  font-family: -apple-system, Roboto, sans-serif;
  font-weight: 400;
  font-size: $font-size;
  color: var(--color-gray-9);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // text selection and copy awoid
  //-webkit-touch-callout: none; // iOS Safari
  //-webkit-user-select: none; // Chrome/Safari/Opera
  //-khtml-user-select: none; // Konqueror
  //-moz-user-select: none; // Firefox
  //-ms-user-select: none; // Internet Explorer/Edge
  //user-select: none; // Non-prefixed version, currently

}

hr {
  border: 1px solid var(--color-gray-6);
  border-bottom: none;
}

p {
  margin-bottom: 0.7em;
}

ol, ul, dl {
  margin-left: 1.5em;
  margin-bottom: 0.5em;
}

img {
  max-width: 100%;
}

pre, code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background: var(--color-gray-4);
  padding: 3px 8px 4px;
  border-radius: 4px;
}

code {
  padding: 2px 8px 3px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Scrollbar
::-webkit-scrollbar {
  width: 0;
}

// Custom ScrollBar
.ScrollbarsCustom-Wrapper {
  top: 0 !important;
  right: 0 !important;
}

.ScrollbarsCustom-Track {
  width: 5px !important;
  right: 0 !important;
  top: 0 !important;
  height: 100% !important;
}

.ScrollbarsCustom-Thumb {
  background-color: #00000066 !important;
}

.ScrollbarsCustom-TrackX {
  display: none;
}

// Colors
.cursor-pointer, .copy-on-click {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.color-default, a.color-default {
  color: var(--color-gray-9);
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary, .ant-typography.color-secondary, a.color-secondary {
  color: var(--color-gray-7);
}

.list-style-secondary {
  li::marker {
    color: var(--color-gray-6);
  }
}

.list-style-normal {
  li::marker {
    color: var(--color-gray-9);
  }
}

.ant-typography.color-secondary {
  color: #909196;
}

.color-success {
  color: $color-success;
}

.color-failure, .color-danger, a.color-danger {
  color: $color-danger;
}

.color-warning, .ant-typography.color-warning {
  color: $color-warning;
}

.color-gold, .ant-typography.color-gold {
  color: $color-gold;
}

.color-white {
  color: var(--color-white);
}

.color-purple {
  color: $color-purple;
}

.box-shadow {
  box-shadow: 2px 3px 7px $color-shadow-2;

  &-none {
    box-shadow: none;
  }

  &-card {
    box-shadow: $card-shadow;
  }
}

.bg-white {
  background-color: var(--color-white);
}

.bg-success {
  background-color: $color-success;
}

.bg-danger {
  background-color: $color-danger;
}

.site-layout-transparent {
  background-color: transparent;
}

.site-layout-background {
  background-color: var(--color-white);
}

.site-landing-background {
  background-color: $color-landing;
}

.bg-blue-light, .bg-blue-light .page-container {
  background-color: var(--color-primary-bg);
}

.bg-gray-light, .bg-gray-light .page-container {
  background-color: var(--color-gray-3);
}

.bg-gray {
  background-color: var(--color-gray-3);
}

// blocks
.block {
  display: block;
}

.inline {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-between {
  justify-content: space-between;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-content-end {
  justify-content: flex-end;
}

.gap {
  &-ps {
    gap: $padding-ps;
  }

  &-sm {
    gap: $padding-sm;
  }
}

.old-school-container {
  width: $screen-lg;
  max-width: $screen-lg;
  margin-left: auto;
  margin-right: auto;
}

// li

//ul:not(.styled) li {
//  list-style-type: none;
//}

// aligns
.align-middle, .vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

// floats
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

// Colors
.color-gray {
  &-8 {
    color: var(--color-gray-8);
  }

  &-7 {
    color: var(--color-gray-6);
  }

  &-6 {
    color: var(--color-gray-6);
  }
}

.text-secondary, .text-muted {
  color: var(--color-gray-7);
}

.text-underline {
  text-decoration: underline;
}

.hover\:text-underline:hover {
  text-decoration: underline;
}

.font-bold {
  font-weight: bolder;
}

.text-ellipsis, .nowrap {
  @include text-ellipsis;
}

// borders
.border-radius {
  &-sm {
    border-radius: $border-radius-sm;
  }

  &-md {
    border-radius: $border-radius-md;
  }

  &-lg {
    border-radius: $border-radius-lg;
  }

  &-lp {
    border-radius: $border-radius-lp;
  }

  &-xl {
    border-radius: $border-radius-xl;
  }
}

.page-container {
  padding: $padding-sm $padding-md $padding-md;

  &.form-container {
    // padding: $padding-sm $padding-md $padding-md;
  }

  .page-section {
    margin-bottom: $padding-md;
  }
}

.page-container-horizontal-padding {
  padding-left: $padding-md+2;
  padding-right: $padding-md;

  &.has-banner {
    padding-top: $padding-sm;
  }
}

// integrations
.integration-list {
  > .ant-row {
    > .ant-col {
      margin-bottom: $padding-sm;
    }
  }

  .int-header {
    @include ellipsis(1);
    color: var(--color-gray-8);
    margin-bottom: $padding-ps;
  }

  .int-item {
    display: flex;
    align-items: center;
    padding: 5px 10px 7px;
    color: var(--color-gray-9);
    border-radius: $border-radius-lg;
    transition: background-color 100ms linear;

    &.has-items {
      background-color: var(--color-gray-4);
    }

    &.not-allowed {
      cursor: default;
      color: var(--color-gray-6);
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: var(--color-gray-3);
    }

    .int-icon {
      font-size: $icon-size-lg;
      height: $icon-size-lg;
      width: $icon-size-lg;
      margin-right: 10px;
      margin-top: -2px;
      display: flex;

      img {
        display: inline-block;
        margin: auto;
        max-height: 100%;

        &.disabled {
          filter: grayscale(100%);
          opacity: 0.3;
        }
      }
    }

    .int-label {
      @include text-ellipsis;
      font-size: $font-size-lg;
    }
  }
}

// Fonts

.font-size {
  &-xs {
    font-size: $font-size-xs;
  }

  &-sm {
    font-size: $font-size-sm;

    .ant-table {
      font-size: $font-size-sm;
    }

    &.ant-btn-sm > * {
      font-size: $font-size-sm;
    }
  }

  &-md {
    font-size: $font-size-md;
  }

  &-lg {
    font-size: $font-size-lg;
  }

  &-h1 {
    font-size: $font-size-h1;
  }

  &-h2 {
    font-size: $font-size-h2;
  }

  &-h3 {
    font-size: $font-size-h3;
  }

  &-h4 {
    font-size: $font-size-h4;
  }
}

.semibold, h2.semibold, h3.semibold, h4.semibold {
  font-weight: 500;
}

// Spaces
.w-34 {
  width: 34px;
}

.h-34 {
  height: 34px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.height-auto {
  height: auto;
}

.zero-px-height {
  height: 0;
  overflow: hidden;
}

.min-h-0 {
  min-height: 0;

  .ant-form-item-control-input {
    min-height: 0;
  }
}

.margin-none {
  margin: 0;

  &-bottom {
    margin-bottom: 0;
  }

  &-vertical {
    margin-top: 0;
    margin-bottom: 0;
  }
}


.margin {
  &-top {
    &-xs {
      margin-top: $padding-xs;
    }

    &-xp {
      margin-top: $padding-xp;
    }

    &-ps {
      margin-top: $padding-ps;
    }

    &-sm {
      margin-top: $padding-sm;
    }

    &-pm {
      margin-top: $padding-pm;
    }

    &-md {
      margin-top: $padding-md;
    }

    &-pl {
      margin-top: $padding-pl;
    }

    &-lg {
      margin-top: $padding-lg;
    }

    &-xl {
      margin-top: $padding-xl;
    }
  }

  &-right {
    &-xxs {
      margin-right: $padding-xs / 2;
    }

    &-xs {
      margin-right: $padding-xs;
    }

    &-xp {
      margin-right: $padding-xp;
    }

    &-ps {
      margin-right: $padding-ps;
    }

    &-sm {
      margin-right: $padding-sm;
    }

    &-pm {
      margin-right: $padding-pm;
    }

    &-md {
      margin-right: $padding-md;
    }

    &-pl {
      margin-right: $padding-pl;
    }

    &-lg {
      margin-right: $padding-lg;
    }

    &-xl {
      margin-right: $padding-xl;
    }
  }

  &-bottom {
    &-xs {
      margin-bottom: $padding-xs;
    }

    &-xp {
      margin-bottom: $padding-xp;
    }

    &-ps {
      margin-bottom: $padding-ps;
    }

    &-sm {
      margin-bottom: $padding-sm;
    }

    &-pm {
      margin-bottom: $padding-pm;
    }

    &-md {
      margin-bottom: $padding-md;
    }

    &-pl {
      margin-bottom: $padding-pl;
    }

    &-lg {
      margin-bottom: $padding-lg;
    }

    &-xl {
      margin-bottom: $padding-xl;
    }
  }

  &-left {
    &-xs {
      margin-left: $padding-xs;
    }

    &-xp {
      margin-left: $padding-xp;
    }

    &-ps {
      margin-left: $padding-ps;
    }

    &-sm {
      margin-left: $padding-sm;
    }

    &-pm {
      margin-left: $padding-pm;
    }

    &-md {
      margin-left: $padding-md;
    }

    &-pl {
      margin-left: $padding-pl;
    }

    &-lg {
      margin-left: $padding-lg;
    }

    &-xl {
      margin-left: $padding-xl;
    }
  }
}

.padding {
  &_default {
    padding: $padding-sm;
  }

  &-top {
    &-xxs {
      padding-top: $padding-xs / 2;
    }

    &-xs {
      padding-top: $padding-xs;
    }

    &-xp {
      padding-top: $padding-xp;
    }

    &-ps {
      padding-top: $padding-ps;
    }

    &-sm {
      padding-top: $padding-sm;
    }

    &-pm {
      padding-top: $padding-pm;
    }

    &-md {
      padding-top: $padding-md;
    }

    &-pl {
      padding-top: $padding-pl;
    }

    &-lg {
      padding-top: $padding-lg;
    }

    &-xl {
      padding-top: $padding-xl;
    }
  }

  &-right {
    &-none {
      padding-right: 0;
    }

    &-xs {
      padding-right: $padding-xs;
    }

    &-xp {
      padding-right: $padding-xp;
    }

    &-ps {
      padding-right: $padding-ps;
    }

    &-sm {
      padding-right: $padding-sm;
    }

    &-pm {
      padding-right: $padding-pm;
    }

    &-md {
      padding-right: $padding-md;
    }

    &-pl {
      padding-right: $padding-pl;
    }

    &-lg {
      padding-right: $padding-lg;
    }

    &-xl {
      padding-right: $padding-xl;
    }
  }

  &-bottom {
    &-xs {
      padding-bottom: $padding-xs;
    }

    &-xp {
      padding-bottom: $padding-xp;
    }

    &-ps {
      padding-bottom: $padding-ps;
    }

    &-sm {
      padding-bottom: $padding-sm;
    }

    &-pm {
      padding-bottom: $padding-pm;
    }

    &-md {
      padding-bottom: $padding-md;
    }

    &-pl {
      padding-bottom: $padding-pl;
    }

    &-lg {
      padding-bottom: $padding-lg;
    }

    &-xl {
      padding-bottom: $padding-xl;
    }
  }

  &-left {
    &-none {
      padding-left: 0;
    }

    &-xs {
      padding-left: $padding-xs;
    }

    &-xp {
      padding-left: $padding-xp;
    }

    &-ps {
      padding-left: $padding-ps;
    }

    &-sm {
      padding-left: $padding-sm;
    }

    &-pm {
      padding-left: $padding-pm;
    }

    &-md {
      padding-left: $padding-md;
    }

    &-pl {
      padding-left: $padding-pl;
    }

    &-lg {
      padding-left: $padding-lg;
    }

    &-xl {
      padding-left: $padding-xl;
    }
  }
}

.padding-none {
  padding: 0;
}

.padding-right {
  padding-right: 40px;
}

.padding-none-horizontal {
  padding-left: 0;
  padding-right: 0;
}

.padding-none-vertical {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-top-none {
  padding-top: 0;
}

.border {
  &-none {
    border: none;
  }
}

.user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

.file-card {
  box-shadow: $card-shadow;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;

  .file-icon {
    font-size: $icon-size-lg;
    margin-right: $padding-ps;
    color: var(--color-gray-7);
  }

  .file-name {
    flex: 1;
  }
}

.overlay-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.overlay-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.nowrap, .text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.opacity-none {
  opacity: 0;
}

.see-through-preloader {
  background-color: var(--color-white);
  position: absolute;
  opacity: 0.59;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  > .anticon {
    font-size: $icon-size-xl;
    color: var(--color-primary);
  }
}

//.see-through-preloader + div {
//  filter: blur(2px);
//}


// z-index
.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-101 {
  z-index: 101;
}

.z-1000 {
  z-index: 1000;
}

// ------------------------------------------

.hide,
.hidden-md,
.field-type-hidden,
.visible-xs,
.visible-xs-inline,
.visible-sm,
.visible-lg,
.visible-xl,
.visible-xxl,
.visible-sm-inline,
.hide-child > *,
.hide-last-child > *:last-child {
  display: none;
}

.hide-important {
  display: none !important;
}

.editable {
  outline: none;
  width: auto;
  cursor: text;
}

@media only screen and (min-width: $screen-lg-min) {
  .visible-lg {
    display: block;
  }
  .hidden-lg {
    display: none;
  }
}


@media only screen and (min-width: $screen-xl-min) {
  .visible-xl {
    display: block;
  }
  .hidden-xl {
    display: none;
  }
}

@media only screen and (min-width: $screen-xxl-min) {
  .visible-xxl {
    display: block;
  }
  .hidden-xxl {
    display: none;
  }
}

@media only screen and (max-width: $screen-sm-max) {
  // Ant: Buttons
  .ant-btn-group.rounded-items-sm .ant-btn {
    border-radius: $border-radius-md;

    &.ant-btn-primary {
      border-right-color: var(--color-primary);
    }
  }

  // blocks and hiding
  .visible-sm-inline {
    display: inline-block;
  }
  .visible-sm {
    display: block;
  }
  .hidden-sm {
    display: none;
  }
  .hidden-sm-important {
    display: none !important;
  }

  .sm-margin-top {
    &-xs {
      margin-top: $padding-xs;
    }

    &-ps {
      margin-top: $padding-ps;
    }

    &-sm {
      margin-top: $padding-sm;
    }

    &-pm {
      margin-top: $padding-pm;
    }

    &-md {
      margin-top: $padding-md;
    }

    &-pl {
      margin-top: $padding-pl;
    }
  }
}

// all small devises
@media only screen and (max-width: $screen-xs-max) {
  // ant styles
  h1.ant-typography, div.ant-typography-h1, div.ant-typography-h1 > textarea, .ant-typography h1 {
    font-weight: 600;
    font-size: 28px;
  }
  .ant-page-header-heading-title {
    font-size: 24px;
  }

  .ant-affix .page-container-horizontal-padding.has-banner {
    padding-top: 0;
  }

  .ant-tabs-tab {
    padding-left: $padding-xp;
  }

  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding-left: $padding-xp + 2;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 $padding-sm;
  }

  .scrollable .ant-modal-body {
    max-height: 450px;
  }

  // blocks and hiding
  .visible-xs-inline {
    display: inline-block;
  }
  .visible-xs {
    display: block;
  }
  .font-size-sm-mobile {
    font-size: $font-size-sm;
  }
  .hidden-xs-important {
    display: none !important;
  }

  .hidden-xs, .ant-btn > span.hidden-xs {
    display: none;
  }

  .xs-margin-top {
    &-none {
      margin-top: 0;
    }

    &-xs {
      margin-top: $padding-xs;
    }

    &-ps {
      margin-top: $padding-ps;
    }

    &-sm {
      margin-top: $padding-sm;
    }

    &-pm {
      margin-top: $padding-pm;
    }

    &-md {
      margin-top: $padding-md;
    }

    &-pl {
      margin-top: $padding-pl;
    }
  }

  .xs-margin-bottom {
    &-none {
      margin-top: 0;
    }

    &-xs {
      margin-bottom: $padding-xs;
    }

    &-ps {
      margin-bottom: $padding-ps;
    }

    &-sm {
      margin-bottom: $padding-sm;
    }

    &-pm {
      margin-bottom: $padding-pm;
    }

    &-md {
      margin-bottom: $padding-md;
    }

    &-pl {
      margin-bottom: $padding-pl;
    }
  }

  // containers

  .bg-gray-light, .bg-gray-light .page-container {
    background-color: var(--color-white);
  }

  .page-container, .page-container.form-container {
    padding: $padding-sm;

    &.container-with-cards {
      padding-top: 0;
    }
  }

  .page-container-horizontal-padding {
    padding-left: $padding-sm+2;
    padding-right: $padding-sm;
  }
  .heading-only-xs .ant-page-header-heading-extra {
    display: none;
  }

  .account-item {
    .page-container {
      padding: 0;
    }

    .account-block, .collapse-card {
      margin: 0;
      box-shadow: none;
      border-radius: 0;
    }
  }

  .padding-top-none {
    padding-top: 0;
  }

  .control-panel-tabs {
    .ant-tabs-tab, .ant-tabs-tab + .ant-tabs-tab {
      padding-left: 0;
    }
  }

  .collapse-row-buttons {
    &.title-controls {
      button {

        &:last-child {

        }

        &.btn-collapse {
          padding-right: 28px;
        }
      }
    }
  }
  .list-default-table {
    .ant-btn.ant-btn-text.ant-btn-lg.ant-btn-icon-only {
      width: 35px;
    }

    .ant-table-thead > tr > th:first-child {
      padding-left: $padding-ps;
    }

    .ant-table-thead > tr > th:last-child {
      padding-right: $padding-ps;
    }

    .ant-table-tbody > tr > td {
      padding-left: $padding-xp;
      padding-right: $padding-xp;
    }
  }
}

// Кастомизация стилей для TinyMCE

.tox {
  .tox-tinymce {
    // специфика правой панели добавляет блоку TinyMCE правый отступ. по другому не убирается
    margin: 0 !important;
  }

  .tox-tooltip {
    display: none !important; /* Hide all tooltips */
  }

  //.tox-toolbar__primary {
  //  min-height: 39px;
  //}

  //.tox-edit-area {
  //  min-height: 55px;
  //}

  .tox-collection--list .tox-collection__item--active .tox-collection__item-label > :not(pre) {
    color: inherit !important;
  }

  .tox-form .tox-label, .tox .tox-form .tox-toolbar-label {
    margin: 5px 0;
  }

  .tox-toolbar__group {
    padding: 0 9px;
  }

  //.tox-tbtn {
  //  width: 32px; // неплохо бы понять зачем это делалось. ибо это ломает ширину меню стилей
  //}

  .tox-editor-container .tox-edit-area::before {
    border-color: var(--color-primary);
  }

  .tox-dialog__body {
    .tox-dialog__body-nav-item, .tox-dialog__body-nav-item--active {
      padding: 3px 10px 5px;
      border-bottom: none;
      margin-bottom: 0;
      margin-top: 5px;
      width: 100%;
    }

    .tox-dialog__body-nav-item:focus, .tox-dialog__body-nav-item--active {
      border-bottom: none;
      color: var(--color-gray-9);
      background: var(--color-primary-light);
      border-radius: $border-radius-sm;
    }
  }
}

