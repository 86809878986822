.drawer {
  :global(.ant-drawer-content-wrapper) {
    max-width: 100%;
  }

  :global(.ant-drawer-header-title) {
    flex-direction: row-reverse;
  }

  :global(.ant-drawer-body) {
    padding: 0;
    overflow: hidden;
  }

  &.raw {
    :global(.ant-drawer-header) {
      border: none;
    }

    :global(.ant-drawer-body) > div:global(.ScrollbarsCustom) {
      height: calc(100vh - 119px) !important;
    }
  }
}


