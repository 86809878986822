@import "../../../vars";
@import "../../../mixins";

.sidebar {
  margin-left: -$left-menu-width;
}

.expandBtn {
  position: absolute;
  z-index: 100;
  top: 92%;
  left: -5px;

  :global(.ant-btn.ant-btn-default) {
    color: var(--color-gray-7);

    &:hover, &:focus {
      color: var(--color-primary-lighten);
    }
  }
}

.show {
  margin-left: 0;

  .expandBtn {
    opacity: 0.8;
    left: $left-menu-width - 15;
  }

}

.hide {

}

.opened {
  transition: margin-left $transition-time $transition-style;
}

.closed {
  transition: margin-left $transition-time $transition-style;
}

.content {
  flex-grow: 1;
}

.listWrap {
  width: $left-menu-width;
  border-right: 1px solid var(--color-gray-4);
  box-shadow: 0 0 10px $color-shadow-2;
  z-index: 2;
}

@media (max-width: $screen-xs-max) {
  .sidebar {
    margin-left: 0;
  }
  .expandBtn {
    display: none;
  }

  .opened {
    &.sidebar, &.leftPanel {
      display: none;
    }

    &.content {
      padding-top: 60px;
    }
  }

  .closed {
    &.content {
      display: none;
    }

    &.sidebar {
      background: var(--color-white);
      width: 100%;
    }

    .listWrap {
      padding-top: 85px;
      box-shadow: none;
      width: 100%;
    }

    .messagesWrap {
      display: none;
    }

    .search {
      margin-left: $padding-ps;
    }
  }
}
