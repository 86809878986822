@import "../../../../../../vars";

.effects-list {
  .container-list {
    .container-buttons {
      display: none;
    }

    .container-collapse:first-child .container-arrow-up,
    .container-collapse:last-child .container-arrow-down {
      display: none;
    }

    .container-collapse:hover, .container-collapse:focus, .container-collapse:active {
      .container-buttons {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .effects-list .container-list .container-buttons {
    display: flex;
  }
}

